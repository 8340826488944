import _ from 'lodash/fp.js'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { itemImgPath } from '../../utils/path.js'
import { useWindowWidth } from '../../utils/hooks.js'
import Info from '../common/item-info.jsx'
import Image from '../common/image.jsx'
import RelatedItems from './related-items.jsx'
import Ruler from './ruler.jsx'
import LoadingImg from './loading-img.jsx'

// try just importing the detail css here? is this not working!?
import '../../styles/detail.css'

const HIDE_RULER_ON_CATEGORY = 'trim'
const getImgWidth = _.propertyOf({
  small: 640,
  large: 1536,
  xlarge: 2560,
})
const NORMAL_KEYWORD = 'normal'
const REVERSE_KEYWORD = 'reverse'
// I know the two words isn't ideal but it reads best when there is reverse_wide.
const KEYWORD_LABEL = {
  [NORMAL_KEYWORD]: 'Front Detail',
  wide: 'Full Pattern',
  [REVERSE_KEYWORD]: 'Reverse Detail',
  reverse_wide: 'Full Reverse Pattern',
}
function ImgBtn({ className, name, onClick }) {
  return (
    <div className={`toggle-${className}`}>
      <button onClick={() => onClick(name)} type="button">
        {`View ${KEYWORD_LABEL[name]}`}
      </button>
    </div>
  )
}

// WARNING: Make sure you're clearheaded if you edit this function.
function getKeywordToggle(activeKeyword, images) {
  if (activeKeyword === 'wide') {
    if (images.reverse_wide) return { toggleWide: NORMAL_KEYWORD, toggleReverse: 'reverse_wide' }
    return { toggleWide: NORMAL_KEYWORD, toggleReverse: REVERSE_KEYWORD }
  }
  if (activeKeyword === REVERSE_KEYWORD) {
    if (images.reverse_wide) return { toggleWide: 'reverse_wide', toggleReverse: NORMAL_KEYWORD }
    return { toggleWide: 'wide', toggleReverse: NORMAL_KEYWORD }
  }
  if (activeKeyword === 'reverse_wide') {
    return { toggleWide: REVERSE_KEYWORD, toggleReverse: 'wide' }
  }
  return { toggleWide: 'wide', toggleReverse: REVERSE_KEYWORD }
}
function shouldShowRuler(catId, activeImageKeyword) {
  if (catId === HIDE_RULER_ON_CATEGORY) return false
  if (activeImageKeyword === NORMAL_KEYWORD) return true
  if (activeImageKeyword === REVERSE_KEYWORD) return true
  return false
}
function Detail({
  item, metaInfo, missingInfoText, onChange,
}) {
  const [isImgLoaded, setImgLoaded] = useState(false)
  const [activeImageKeyword, setActiveImage] = useState(NORMAL_KEYWORD)
  const windowSize = useWindowWidth()
  if (!item || !item.pattern.category) return <p>{missingInfoText}</p>
  const imgWidth = getImgWidth(windowSize)
  const imgix = `?w=${imgWidth}&fm=pjpg`
  const catId = item.pattern.category.id
  const showRuler = shouldShowRuler(catId, activeImageKeyword)
  const images = _.keyBy('keyword', item.images)
  const image = images[activeImageKeyword]
  const imagePath = (image && itemImgPath(item, activeImageKeyword)) || null
  // const hash = (image && image.blurhash) || null
  const hash = null
  const height = (!showRuler && image && Math.round(image.pixelRatio * imgWidth)) || '100%'
  const { toggleWide, toggleReverse } = getKeywordToggle(activeImageKeyword, images)
  // const { isClientApp } = metaInfo
  // const style = isClientApp ? detailStyles.inApp : detailStyles.staticRender
  return (
    <div id="detailWrapper" className={catId}>
      <div id="detailHeader">
        <Info item={item} metaInfo={metaInfo} onChange={onChange} />
        <div className="buttons">
          { item.pattern.colorItems.length > 1 && (
          <RelatedItems
            activeId={item.colorId}
            pattern={item.pattern}
            metaInfo={metaInfo}
            onChange={onChange}
          />
          )}
          { images.wide && <ImgBtn className="wide" onClick={setActiveImage} name={toggleWide} /> }
          { images.reverse && <ImgBtn className="reverse" onClick={setActiveImage} name={toggleReverse} /> }
          {!isImgLoaded && <LoadingImg />}
        </div>
      </div>
      <div className="imageWrapper">
        <Image
          key={item.id + activeImageKeyword}
          alt={item.id}
          onLoad={() => setImgLoaded(true)}
          hash={hash}
          path={imagePath}
          imgix={imgix}
          height={height}
          width="100%"
        />
      </div>
      {showRuler && (<Ruler categoryId={catId} showXl={windowSize === 'xlarge'} />)}
    </div>
  )
}

Detail.propTypes = {
  item: PropTypes.object,
  location: PropTypes.object,
  // detailClose: PropTypes.func.isRequired,
  // disclaimer: PropTypes.object,
  metaInfo: PropTypes.shape({
    isClientApp: PropTypes.bool,
  }),
  missingInfoText: PropTypes.string,
  onChange: PropTypes.func,
}
Detail.defaultProps = {
  // detailClose: noop,
  location: {},
  metaInfo: { // for when on pre-rendered detail page with no pager.
    isClientApp: false,
  },
  missingInfoText: 'No item data or invalid information available. Loading failed. Invalid URL.',
  onChange: null,
}

export default Detail
