import React from 'react'
import PropTypes from 'prop-types'

function Address(props) {
  const {
    addressCountry, addressLocality, addressRegion,
    postalCode, streetAddress, streetAddressDetail,
  } = props

  return (
    <div className="adr">
      <div className="street-address">{streetAddress}</div>
      {streetAddressDetail && (
        <div className="extended-address">{streetAddressDetail}</div>
      )}
      {addressLocality && (
      <span className="locality">
        {addressLocality}
        ,
        {' '}
      </span>
      )}
      {addressLocality && (
      <span className="region">
        {addressRegion}
        {' '}
      </span>
      )}
      {postalCode && (
      <span className="postal-code">
        {postalCode}
        {' '}
      </span>
      )}
      {addressCountry && (<div className="country-name">{addressCountry}</div>)}
    </div>
  )
}
Address.propTypes = {
  addressCountry: PropTypes.string,
  addressLocality: PropTypes.string,
  addressRegion: PropTypes.string,
  postalCode: PropTypes.string,
  streetAddress: PropTypes.string,
  streetAddressDetail: PropTypes.string,
}
Address.defaultProps = {
  addressCountry: null,
  addressLocality: null,
  addressRegion: null,
  postalCode: null,
  streetAddress: null,
  streetAddressDetail: null,
}
export default Address
