const style = {
  active: {
    display: 'block',
    position: 'relative',
    border: '2px solid #848484',
    boxShadow: '#848484 0 0 1em inset',
  },
  closed: {
    boxShadow: '0 0 0 0 rgba(65,65,45,0)',
    height: '1.5rem',
    border: '0',
  },
  open: {
  },
  plain: {
    transform: 'rotate(0deg)',
    transition: 'all 300ms',
  },
  rotated: {
    transform: 'rotate(-180deg)',
    transition: 'all 300ms',
  },
}
export default style
