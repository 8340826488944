import _ from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { addHistory, createHistoryListener, replaceHistory } from '../../utils/index.js'
import { collectionPathEncode, createCollectionUrl, collectionPathParse } from '../../utils/path.js'
import { buildResultItems, getCollectionMeta, getSearchOptions } from '../../utils/search.js'

import Collection from './collection.jsx'

// DYNAMIC SEARCH PAGES
// This wrapper builds up data as if it was done by a static page and sends it along.

// options: ['pageType', 'displayStyle', 'sortSlug', 'perPage', 'currentPage']
// search: categorySlug, itemId, filters: {}, query: ''
function searchMods(flagged) {
  return (meta) => {
    if (meta.search.query === 'flagged') {
      return _.set('search.query', null, { ...meta, ids: flagged })
    }
    return meta
  }
}
function CollectionSearch(props) {
  const {
    flagged, isAnon, path, search,
  } = props
  const [{ backTo, latestPath }, setPaths] = useState({ backTo: path, latestPath: path })
  console.log(path, latestPath)
  function setPath(nextPath) {
    return setPaths({
      backTo: nextPath.includes('detail') ? backTo : nextPath,
      latestPath: nextPath,
    })
  }
  useEffect(() => {
    function onChange({ location }) {
      console.log('history change', location.pathname)
      setPath(location.pathname)
    }
    return createHistoryListener(onChange)
  })
  const meta = collectionPathParse(latestPath)
  meta.backTo = backTo
  meta.isAnon = isAnon

  function update(nextMeta) {
    const nextPath = createCollectionUrl(nextMeta)
    setPath(nextPath)
    if (_.isString(nextPath)) addHistory(nextPath)
  }
  const searchOptions = _.flow(searchMods(flagged), getSearchOptions)(meta)
  const searchResults = search(searchOptions)
  const metaInfo = getCollectionMeta(meta, searchResults)
  // console.log(metaInfo)
  if (metaInfo.pageCount < metaInfo.currentPage) {
    metaInfo.currentPage = metaInfo.pageCount
    const nextPath = collectionPathEncode(metaInfo)
    setPath(nextPath)
    replaceHistory(nextPath)
  }

  const items = buildResultItems(metaInfo, searchResults.data.items)
  // console.log('CollectionSearch', latestPath, metaInfo)
  return (
    <Collection
      items={items}
      metaInfo={metaInfo}
      onChange={update}
    />
  )
}
CollectionSearch.propTypes = {
  isAnon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
}
export default CollectionSearch
