import _ from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { BlurhashCanvas } from 'react-blurhash'
import { useHotkeys } from 'react-hotkeys-hook'
import { imgUrl, isBrowser } from '../../utils/index.js'
import Loading from '../detail/loading-img.jsx'

const query = graphql`
  query ImgUrl {
    site {
      siteMetadata {
        imgUrlBase
      }
    }
  }
`
const selector = _.get('site.siteMetadata.imgUrlBase')

function ImageEl({
  alt, hash, height, width, path, imgix, ...props
}) {
  const imgUrlBase = selector(useStaticQuery(query))
  const [isImgLoaded, setImgLoaded] = useState(false)
  const [forcedHash, setForcedHash] = useState(false)
  useHotkeys('ctrl+h', () => hash && setForcedHash((prev) => !prev))
  const imgSrc = imgUrl(imgUrlBase, path, imgix)
  useEffect(() => {
    if (isImgLoaded || !isBrowser() || !imgSrc) return _.noop
    function imgLoadListener() {
      setImgLoaded(true)
    }
    const img = new Image()
    img.addEventListener('load', imgLoadListener)
    img.src = imgSrc
    function cleanup() {
      img.removeEventListener('load', imgLoadListener)
    }
    return cleanup
  }, [imgSrc, isImgLoaded])

  if (!path && !hash) return <div className="no-img" style={{ height, width }}>NO IMG</div>
  if (hash && (!isImgLoaded || forcedHash)) {
    const style = { height, width }
    return <BlurhashCanvas height={32} width={32} hash={hash} punch={1} style={style} />
  }
  const loadingText = 'Loading image...'
  if (!isImgLoaded || forcedHash) {
    return <Loading />
  }
  // console.log('loaded', _.now())
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <img
      {...props}
      alt={alt}
      src={imgSrc}
    />
  )
}
ImageEl.propTypes = {
  alt: PropTypes.string.isRequired,
  hash: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgix: PropTypes.string,
  path: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
ImageEl.defaultProps = {
  hash: null,
  height: undefined,
  imgix: null,
  path: null,
  width: undefined,
}
export default ImageEl
