import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import { filterState } from '../../utils/index.js'
import { logout, useUserAnon } from '../../services/auth.js'

import NavItem from './nav-item.jsx'

/* eslint-disable react/jsx-props-no-spreading */
function Menu({
  activePath, isAnonUser, items, metaInfo, onChange, toggleSearch,
}) {
  const { categoryId } = metaInfo
  const confirmedAnonUser = useUserAnon(isAnonUser)
  function isActive({ group, url }) {
    if (!url) return false
    if (activePath === '/') return activePath === url
    if (activePath === '/new') {
      return url === activePath
    }
    return (
      _.startsWith(activePath, url) || _.startsWith(url, activePath) || group.endsWith(categoryId)
    )
  }
  const navItems = items.filter(filterState({ isAnon: confirmedAnonUser }))

  const actions = {
    logout: logout(() => window.location.reload()),
    search: toggleSearch,
  }
  return (
    <nav>
      <ul className="menu list-reset list-inline">
        {_.map((link) => (
          <NavItem
            {...link}
            key={_.kebabCase(link.url)}
            active={isActive(link)}
            onChange={onChange}
            metaInfo={metaInfo}
            actions={actions}
          />
        ), navItems)}
      </ul>
    </nav>
  )
}
Menu.propTypes = {
  activePath: PropTypes.string.isRequired,
  isAnonUser: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  metaInfo: PropTypes.shape({
    categoryId: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  toggleSearch: PropTypes.func.isRequired,
}
Menu.defaultProps = {
  isAnonUser: true,
  onChange: null,
}
export default Menu
