import _ from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'

function checkValidators({ isAnon, isSearching }) {
  return ({ name }) => {
    if ((!isSearching || isAnon) && name === 'tg') return false
    return true
  }
}

function FilterGroups(props) {
  const {
    activeId, items, onChange, className, clearBtn, metaInfo,
  } = props
  const activeStyle = { fontWeight: 'bold', color: '#4d4d4d' }
  const getStyle = (id) => (id === activeId ? activeStyle : null)
  const handleChange = (e) => onChange(e.target.value)
  return (
    <div role="group" aria-labelledby="active-filter" className={className}>
      {items.filter(checkValidators(metaInfo)).map((item) => (
        <label key={item.name} style={getStyle(item.name)}>
          <button type="button" name="activeFilter" value={item.name} onClick={handleChange} />
          {item.title}
        </label>
      ))}
      {clearBtn}
    </div>
  )
}
FilterGroups.propTypes = {
  activeId: PropTypes.string.isRequired,
  clearBtn: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  metaInfo: PropTypes.shape({
    isSearching: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}
export default FilterGroups
