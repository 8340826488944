import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { collectionPathEncode } from '../utils/path.js'

function NewRelease(props) {
  const {
    patternId, description, readMore, name, image, datePublished,
  } = props
  // The actual static location of the collection search web app.
  const searchCollectionUrl = '/collection/[...]/'
  // The URL searchHash section is built from search obj.
  const search = { query: patternId }
  // We send the virtual URL that has search info via state props.
  const nextPath = collectionPathEncode({ search })
  return (
    <div key={patternId} className="newRelease card">
      <h3 className="uppercase">{name}</h3>
      <Link className="nostyles" to={searchCollectionUrl} state={{ nextPath }}>
        <img alt={name} src={image} />
      </Link>
      <div className="blurb">
        {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
        {!description && patternId && <div className="patterns">{patternId}</div>}
        {(readMore !== false) && <Link className="read-more" to={`/new/${patternId}`}>… more</Link>}
      </div>
      <div className="dateAdded">
        <p>{datePublished}</p>
      </div>
    </div>
  )
}

NewRelease.propTypes = {
  datePublished: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  patternId: PropTypes.string.isRequired,
  readMore: PropTypes.bool,
}
NewRelease.defaultProps = {
  readMore: true,
}

export default NewRelease
