// import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import JSONTree from 'react-json-tree'
import { itemImgPath } from '../../utils/path.js'
import Info from '../common/item-info.jsx'
import Image from '../common/image.jsx'

const IMG_WIDTH = 500
const pallet = ['muted', 'mutedLight', 'mutedDark', 'vibrant', 'vibrantLight', 'vibrantDark']
function Color({ hex, text }) {
  const message = text ? `${hex} ${text}` : hex
  return (
    <div key={hex.slice(1)} style={{ backgroundColor: hex, flex: '1 0 auto' }}>{message}</div>
  )
}
function Debug({
  item, metaInfo, missingInfoText,
}) {
  if (!item) return <p>{missingInfoText}</p>
  const imgix = `?w=${IMG_WIDTH}&fm=pjpg`
  // const height = (!showRuler && image && Math.round(image.pixelRatio * IMG_WIDTH)) || '100%'
  return (
    <div id="detail-debug">
      <Info item={item} isAnonUser={false} metaInfo={metaInfo} />
      <div style={{ float: 'left', maxWidth: '20%', textAlign: 'left' }}>
        <JSONTree data={{ item, metaInfo }} hideRoot />
      </div>
      {item.images && item.images.map(({
        blurhash, colors, dominantColors, keyword, pixelRatio,
      }) => (
        <div key={keyword}>
          <span>{keyword}</span>
          <Image
            alt={item.id}
            path={itemImgPath(item, keyword)}
            imgix={imgix}
          />
          <Image
            alt={item.id}
            hash={blurhash}
            height={Math.round(pixelRatio * IMG_WIDTH)}
            width={IMG_WIDTH}
          />
          <div style={{ display: 'flex', height: 50 }}>
            {colors.map(Color)}
          </div>
          <div style={{ display: 'flex', height: 50 }}>
            {pallet.filter((color) => dominantColors[color]).map((color) => (
              <div
                key={color}
                style={{ backgroundColor: dominantColors[color].hex, flex: '1 0 auto' }}
              >
                {color}
                {dominantColors[color].hex}
              </div>
            ))}

          </div>
        </div>
      ))}
    </div>
  )
}

Debug.propTypes = {
  item: PropTypes.object,
  // detailClose: PropTypes.func.isRequired,
  // disclaimer: PropTypes.object,
  metaInfo: PropTypes.shape({
    isClientApp: PropTypes.bool,
  }),
  missingInfoText: PropTypes.string,
}
Debug.defaultProps = {
  // detailClose: noop,
  location: {},
  metaInfo: { // for when on pre-rendered detail page with no pager.
    isClientApp: false,
  },
  missingInfoText: 'No item data. Loading or invalid URL.',
}

export default Debug
