import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'
import { getColumns } from '../../utils/collection.js'

// import ItemFav from './Fav/ItemFav'
import { collectionPathEncode, collectionPathParse } from '../../utils/path.js'

function getBackTo(metaInfo, item) {
  const backTo = _.get('backTo', metaInfo) || _.get('path', metaInfo)
  if (!backTo || backTo.includes('/detail')) {
    return collectionPathEncode({ categorySlug: item.pattern.category.slug })
  }
  return backTo
}
function ItemInfo({
  metaInfo, item, onChange, showClose,
}) {
  const { isAnon } = metaInfo
  const fields = getColumns(metaInfo, { isAnon }, item)
  const backTo = getBackTo(metaInfo, item)
  const closeText = 'Close'
  function handleClose(e) {
    e.preventDefault()
    onChange(collectionPathParse(backTo))
    return false
  }
  const { discontinued } = item
  const className = classnames('item-information', { discontinued })
  return (
    <div className={className}>
      <ul className="list-reset">
        {_.map(
          ({ field: { id, valuePath }, label }) => (
            <li key={id} className={`infoItem ${id}`}>
              <h3 className="uppercase">{label}</h3>
              <p>
                {_.isArray(valuePath) && valuePath.map((x) => <span key={x}>{`${_.get(x, item)} `}</span>)}
                {_.isString(valuePath) && <span>{_.get(valuePath, item)}</span>}
              </p>
            </li>
          ),
          fields,
        )}
        {showClose && backTo && (
          <li className="close">
            {!onChange && <Link to={backTo}>{closeText}</Link>}
            {onChange && <a href={backTo} onClick={handleClose}>{closeText}</a>}
          </li>
        )}
      </ul>
    </div>
  )
}

ItemInfo.propTypes = {
  location: PropTypes.object,
  item: PropTypes.object.isRequired,
  metaInfo: PropTypes.object,
  onChange: PropTypes.func,
  showClose: PropTypes.bool,
}
ItemInfo.defaultProps = {
  onChange: null,
  showClose: true,
}

export default ItemInfo
