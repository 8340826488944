import { parse, stringify } from 'query-string'
import { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import { getItem, setItem } from './index'

export const useQuery = (location, options) => {
  const [state, setState] = useState(parse(location.search, options))
  const setQuery = (values) => {
    const newQuery = { ...state, ...values }
    setState(newQuery)
    navigate(`?${stringify(newQuery)}`)
  }
  return [state, setQuery]
}

function getLocalStorage(key, initialValue) {
  // Get from local storage by key
  const item = getItem(key)
  // Parse stored json or if none return initialValue
  if (!item) return initialValue
  if (item === 'null') return initialValue
  if (item === 'undefined') return initialValue
  return JSON.parse(item)
}
// https://usehooks.com/useLocalStorage/
export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      return getLocalStorage(key, initialValue)
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return [storedValue, setValue]
}

export default useQuery

// Window Width
function getWidthSize(ww) {
  if (ww < 640) return 'small'
  if (ww < 1536) return 'large'
  return 'xlarge'
}
export function useWindowWidth() {
  const [windowSize, setWidthSize] = useState('large')
  useEffect(() => {
    function handleResize() {
      const size = getWidthSize(window.innerWidth)
      if (windowSize !== size) {
        setWidthSize(size)
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize])

  return windowSize
}
