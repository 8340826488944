import _ from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getCatBySlug } from '../utils/index.js'
import {
  buildResultItems, createSearch, getCollectionMeta, getSearchOptions,
} from '../utils/search.js'
import { printMeta } from '../utils/path.js'
import { filterFields } from '../data/filter-fields.js'

import PrintCoverPage from '../components/collection/print-cover.jsx'
import PricelistTable from '../components/pricelist/table.jsx'

import '../styles/print.css'

const CHUNK_LINES = 64
const TITLE_PG_HEADER_LINES = 11
const CONTENT_CHARS_PER_LINE = 28
const getPtnId = _.get('pattern.patternId')
const matchesPtnId = (patternId) => _.flow(getPtnId, _.eq(patternId))
function contentSize({ content }) {
  if (!content || content.length < CONTENT_CHARS_PER_LINE) return 0
  return Math.ceil(content.length / CONTENT_CHARS_PER_LINE)
}

function getChunkSize(items, targetSize) {
  let chunkLines = 0
  let chunkSize = 0
  let patternId = getPtnId(_.first(items))
  let prevContent = 0
  _.forEach(
    ({ pattern }) => {
      if (pattern.patternId === patternId) {
        chunkLines += 1
        prevContent = 0
      } else { // new pattern
        chunkLines += (2.9 + prevContent)
        prevContent = contentSize(pattern)
      }
      if (Math.ceil(chunkLines) >= (targetSize)) return false
      chunkSize += 1
      patternId = pattern.patternId
      return (chunkLines < targetSize)
    },
    items,
  )
  return { chunkSize, chunkLines }
}

function getChunk(metaInfo, items, index) {
  const targetSize = index ? CHUNK_LINES : CHUNK_LINES - TITLE_PG_HEADER_LINES
  const size = getChunkSize(items, targetSize)
  if (items.length <= size.chunkSize) {
    const chunkItems = buildResultItems(metaInfo, items)
    return {
      ...size,
      items: chunkItems,
      metaInfo: { ...metaInfo, hasNextPage: false, perPage: items.length },
      key: _.first(items).id,
    }
  }
  // Get patternId of the first item in next chunk.
  const patternId = getPtnId(items[size.chunkSize])
  // Find first index that has patternId.
  const nextPgIndex = _.findIndex(matchesPtnId(patternId), items)
  const chunkItems = items.slice(0, nextPgIndex)
  const chunkPatterns = buildResultItems(metaInfo, chunkItems)
  return {
    ...size,
    patternId,
    items: chunkPatterns,
    metaInfo: {
      ...metaInfo,
      hasNextPage: true,
      perPage: chunkItems.length,
    },
    nextItems: items.slice(nextPgIndex),
    key: _.first(items).id,
  }
}
function getItemChunks(metaInfo, items) {
  let remaining = items
  const chunks = []
  // console.log('getItemChunks', remaining.length)
  while (remaining.length > 0) {
    const chunk = getChunk(metaInfo, remaining, chunks.length)
    remaining = chunk.nextItems || []
    delete chunk.nextItems
    chunks.push(chunk)
    // console.log('chunk', remaining.length, chunk)
    if (!chunk.metaInfo.hasNextPage) break
  }
  return chunks
}

function Page({
  category, children, dateModified, page, pages, x,
}) {
  const categoryTxt = (category && category.label) || 'ALL ITEMS'
  const footerText = `Rogers & Goffigon ${categoryTxt} Price List`
  // const debugInfo = `${x.patternId} - ${x.chunkSize} / ${Math.round(x.chunkLines)}`
  const pager = `${page} / ${pages}`
  const dateString = new Date(dateModified).toLocaleDateString()
  const dateText = 'Effective'
  const style = {
    clear: 'both',
    position: 'relative',
    width: '8.5in',
    height: '11in',
    margin: 0,
    padding: '0.5in 0.5in 0.25in 0.5in',
    pageBreakAfter: 'always',
    delay: 4,
    fontFamily: 'EB Garamond',
  }
  return (
    <div className="pagebreak" style={style}>
      {children}
      <div className="margin-bottom">
        <div className="bottom-left">
          {footerText}
        </div>
        <div className="bottom-center">
          {pager}
        </div>
        <div className="bottom-right">
          {dateText}
          {' '}
          {dateString}
        </div>
      </div>
    </div>
  )
}
/* eslint-disable react/jsx-props-no-spreading */
function PrintPdfCollection(props) {
  const {
    data: { patterns, site: { meta: { dateModified } } },
    params,
    pageContext,
  } = props
  const search = createSearch(patterns.nodes, filterFields)
  const categoryId = params.collection || pageContext.category
  const category = getCatBySlug(categoryId)
  const meta = printMeta(categoryId)
  const searchOptions = getSearchOptions(meta)
  const searchResults = search(searchOptions)
  const metaInfo = getCollectionMeta(meta, searchResults)
  metaInfo.isAnon = false

  const chunks = getItemChunks(metaInfo, searchResults.data.items)
  // console.log(category)
  const pages = chunks.length
  return (
    <div id="pdfpage">
      {
        chunks.map((x, index) => (
          <Page category={category} dateModified={dateModified} page={index + 1} pages={pages} key={x.key} x={x}>
            {index === 0 && <PrintCoverPage category={category} />}
            <PricelistTable
              items={x.items}
              metaInfo={x.metaInfo}
              onChange={_.noop}
              isAnonUser={false}
            />
          </Page>
        ))
      }
    </div>
  )
}

PrintPdfCollection.propTypes = {
  data: PropTypes.shape({
    patterns: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object),
    }),
    site: PropTypes.shape({
      meta: PropTypes.shape({
        dateModified: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      nextPath: PropTypes.string,
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    category: PropTypes.string,
  }),
  params: PropTypes.shape({
    collection: PropTypes.string,
  }).isRequired,
}
export default PrintPdfCollection

export const pageQuery = graphql`
  query CollectionPrintPdfSearchQuery {
    patterns: allPatternsJson(
      filter: { discontinued: { ne: true } }
      sort: { fields: [name, id], order: ASC }
    ) {
      nodes {
        ...PrintCollectionSearchFields
      }
    }
    site {
      meta: siteMetadata {
        dateModified
      }
    }
  }
`
