import React from 'react'
import StateProvider from './src/store/stateProvider.jsx'

import './src/styles/global.css'
import './src/styles/about.css'
import './src/styles/nav.css'
import './src/styles/pricelist.css'
import './src/styles/grid.css'
import './src/styles/relatedColors.css'
import './src/styles/swiperOverride.css'
import './src/styles/ccFormOverride.css'

export const wrapRootElement = ({ element }) => <StateProvider>{element}</StateProvider>
