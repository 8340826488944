import {
  flow, get, map,
} from 'lodash/fp'
import { oneOf } from 'understory'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'
import cellStyles from './styles.js'
import { getPatternItemId } from '../../utils/index.js'
import { itemUrl, getImgPath, getNormalImg } from '../../utils/path.js'
import Image from '../common/image.jsx'

const IMGIX_EXT = '?w=40&h=18&fit=crop'
const linkFieldIds = ['itemId', 'color', 'imgPath']
const isLinkField = flow(get('field.id'), oneOf(linkFieldIds))

function getVal({ id, collection }, pattern, index, rowSpan) {
  if (id === 'id') return getPatternItemId(pattern, index)
  // Fields that are attached to the pattern.
  if (collection === 'pattern') {
    const fieldVal = pattern[id]
    // issue #285 Replace white space between H and V with line break.
    if (fieldVal && id === 'repeat' && rowSpan) {
      const fieldValSplit = fieldVal.split(' V')
      if (fieldValSplit[1]) {
        return (
          <div>
            {fieldValSplit[0]}
            <br />
            {`V${fieldValSplit[1]}`}
          </div>
        )
      }
    }
    return fieldVal
  }
  const colorInfo = pattern.colorItems[index]
  const fieldVal = colorInfo[id]
  if (id === 'images') {
    const { blurhash } = getNormalImg(fieldVal) || {}
    const imagePath = getImgPath(pattern.patternId, colorInfo.colorId, 'normal')
    // Need some kind of styles here.
    return (
      <Image
        alt={getPatternItemId(pattern, index)}
        path={imagePath}
        imgix={IMGIX_EXT}
        hash={blurhash}
      />
    )
  }
  return fieldVal
}

function PricelistRow({
  columns, index, path, pattern,
}) {
  const isPattern = index === 0
  const isLast = index + 1 === pattern.colorItems.length
  const { category } = pattern

  const colorInfo = pattern.colorItems[index]
  const hasImage = colorInfo.images && colorInfo.images.length

  const className = classnames(
    {
      color: !isPattern,
      discontinued: colorInfo.discontinued,
      pattern: isPattern,
      last: isLast,
    },
    category.id,
  )

  const itemPage = itemUrl(getPatternItemId(pattern, index))

  function cellValue({ field, printWhenColor, rowSpan }) {
    if (index === 0) return getVal(field, pattern, index, rowSpan)
    return printWhenColor ? getVal(printWhenColor, pattern, index) : null
  }

  const printedColumns = isPattern ? columns : columns.filter(({ rowSpan }) => !rowSpan)
  return (
    <tr className={className}>
      {map(
        (column) => (
          <td
            key={column.field.id}
            className={column.field.id}
            style={cellStyles({ length: columns.length }, column.field.id)}
            rowSpan={isPattern && column.rowSpan ? pattern.colorItems.length : null}
          >
            {
              isLinkField(column) && hasImage
                ? (<Link to={itemPage} state={{ backTo: path }}>{cellValue(column)}</Link>)
                : cellValue(column)
            }
          </td>
        ),
        printedColumns,
      )}
    </tr>
  )
}

PricelistRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  pattern: PropTypes.shape({
    category: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    colorItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
}

export default PricelistRow
