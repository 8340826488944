import _ from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'

function Telephone({ number, type }) {
  const uniqueKey = `num-${_.kebabCase(number)}`
  const displayNumber = number.replace(/^\+1 /, '')
  // Could do some conditionals for type of number.
  return (
    <li key={uniqueKey}>
      {/* <span>
        {type}
        {': '}
      </span> */}
      {displayNumber}
    </li>
  )
}
Telephone.propTypes = {
  number: PropTypes.string.isRequired,
}
export default Telephone
