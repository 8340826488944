import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash/fp'
import { Link } from 'gatsby'
import { itemUrl } from '../../utils/path.js'

function LinkEl({
  children, id, removed, patternId,
}) {
  const backTo = `/collection/${patternId}`
  if (removed) return <div>{children}</div>
  return <Link to={itemUrl(id)} state={{ backTo }}>{children}</Link>
}

const IMG_WIDTH = 1500

function Slide({ item }) {
  // console.log(item)
  return (
    <div
      className="slide"
      style={{
        width: '100%',
        height: '100%',
        background: `url(${item.image}?w=${IMG_WIDTH})`,
        backgroundSize: 'cover',
        backgroundRepear: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <ul className="list-reset">
        {map(
          ({
            id, color, name, removed, patternId,
          }) => (
            <li className="hover-target" key={id}>
              <LinkEl id={id} removed={removed} patternId={patternId}>
                <div className="popover">
                  <h3 className="name">{name}</h3>
                  <p className="color">{color}</p>
                </div>
              </LinkEl>
            </li>
          ),
          item.items,
        )}
      </ul>
    </div>
  )
}
Slide.propTypes = {
  item: PropTypes.object.isRequired,
}
export default Slide
