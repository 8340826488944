import { map } from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'
import { getColumns } from '../../utils/collection.js'

import TableHeader from './table-head.jsx'
import TableRow from './table-row.jsx'
// import PricelistFoot from './table-foot.jsx'

function PricelistTable({
  metaInfo, items,
}) {
  const { categoryId, isAnon } = metaInfo
  const columns = getColumns(metaInfo, { isAnon })
  return (
    <div className="tableJacket">
      <table className={categoryId}>
        <TableHeader columns={columns} categoryId={categoryId} />
        {map(
          ({ node }) => (
            <tbody key={node.patternId}>
              {node.colorItems.map(({ colorId }, index) => (
                <TableRow
                  key={`${node.patternId}-${colorId}`}
                  columns={columns}
                  index={index}
                  path={metaInfo.path}
                  pattern={node}
                />
              ))}
            </tbody>
          ),
          items,
        )}
      </table>
    </div>
  )
}
PricelistTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  metaInfo: PropTypes.shape({
    categoryId: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    isAnon: PropTypes.bool,
  }).isRequired,
}
PricelistTable.defaultProps = {
}
export default PricelistTable
