const pagerStyle = {
  detail: {
    // width: '100vw',
    // position: 'absolute',
    // zIndex: '9',
    // left: '50%',
    // transform: 'translateX(-50%)',
  },
}
export default pagerStyle
