import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

function createHandleClick(props) {
  const {
    active, code, group, metaInfo, onChange, url,
  } = props
  return (e) => {
    e.preventDefault()
    if (!metaInfo.isClientApp || !onChange) {
      return navigate('/collection/[...]', { state: { nextPath: url } })
    }
    if (group.endsWith('performance')) {
      onChange(['search', 'filters'], { dn: ['pf'], cg: null })
      return false
    }
    // console.log('NAV-ITEM', metaInfo)
    onChange(['search', 'filters'], { cg: active ? null : [code] })
    return false
  }
}

function CollectionLink(props) {
  const {
    active, children, name, url,
  } = props
  const handleClick = createHandleClick(props)
  const displayHref = active ? '/collection/any' : url
  return (<a href={displayHref} onClick={handleClick} title={name}>{children}</a>)
}
CollectionLink.propTypes = {
  active: PropTypes.bool,
  metaInfo: PropTypes.shape({
    isClientApp: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
CollectionLink.defaultProps = {
  active: false,
  code: null,
  onChange: null,
}

export default CollectionLink
