// WARNING: DO NOT EDIT THIS FILE! See /data/field-options.js
const collection = {
  "pgSizeMultiplier": 12,
  "pgSizes": [
    4,
    8,
    24,
    417
  ],
  "defaultPgSizeIndex": 0,
  "displayStyles": [
    {
      "value": "list",
      "label": "List",
      "columnOrder": [
        "name",
        "id",
        "images",
        "color",
        "price",
        "content",
        "repeat",
        "approxWidth",
        "approxSize",
        "approxThick"
      ],
      "sortByOptions": [
        {
          "sortBy": "name",
          "value": "name",
          "label": "A-Z"
        }
      ]
    },
    {
      "value": "grid",
      "label": "Grid",
      "sortByOptions": [
        {
          "sortBy": "pattern___name",
          "sortPath": "pattern.name",
          "value": "name",
          "label": "A-Z"
        },
        {
          "sortBy": "colorPosition",
          "sortPath": "colorPosition",
          "value": "color",
          "label": "Color"
        }
      ]
    },
    {
      "value": "grid-color",
      "label": "Grid Color",
      "hidden": true,
      "sortByOptions": [
        {
          "sortBy": "colorPosition",
          "sortPath": "colorPosition",
          "value": "color",
          "label": "Color"
        }
      ]
    },
    {
      "value": "detail",
      "label": "Detail",
      "perPage": 1,
      "hidden": true,
      "sortByOptions": [
        {
          "value": null
        }
      ]
    },
    {
      "value": "detailx",
      "hidden": true,
      "label": "Detail Debug",
      "perPage": 1,
      "sortByOptions": [
        {
          "value": null
        }
      ]
    }
  ],
  "defaultMeta": {
    "currentPage": 1,
    "displayStyle": "grid",
    "pageType": "collection",
    "perPage": 48
  },
  "defaultCategory": "textile",
  "pageSizes": [
    {
      "value": 48,
      "label": "48"
    },
    {
      "value": 96,
      "label": "96"
    },
    {
      "value": 288,
      "label": "288"
    },
    {
      "value": 5004,
      "label": "All"
    }
  ]
}
export default collection
