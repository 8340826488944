import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { collectionPathEncode } from '../utils/path.js'
import Logo from './common/logo.jsx'

function NoticeBox({
  closeNotice, item: {
    image, name, patternId, description,
  },
}) {
  // The actual static location of the collection search web app.
  const searchCollectionUrl = '/collection/'
  // The URL searchHash section is built from search obj.
  const search = { query: patternId }
  // We send the virtual URL that has search info via state props.
  const nextPath = collectionPathEncode({ search })

  return (
    <section
      id="newReleaseBanner"
      className="hidden"
    >
      <Link className="nostyles" to={searchCollectionUrl} state={{ nextPath }}>
        <div className="card">
          <div className="logo"><Logo /></div>
          <h2>{name}</h2>
          <div className="fabricImg">
            <img src={image} alt={name} />
          </div>
          {description && (
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          )}

        </div>
      </Link>
      <button
        type="button"
        className="close"
        onClick={closeNotice}
      >
        Close
      </button>
    </section>
  )
}

NoticeBox.propTypes = {
  closeNotice: PropTypes.func.isRequired,
  item: PropTypes.shape({
    link: PropTypes.string,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    patternId: PropTypes.string.isRequired,
    readMore: PropTypes.bool,
  }).isRequired,
}

export default NoticeBox
