import _ from 'lodash/fp'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { filterFieldsNoCat as filterFields } from '../../data/filter-fields.js'
import FilterOptions from './filter-options.jsx'
import FilterGroups from './filter-groups.jsx'

const LEATHER = 'leather'
const LEATHER_TYPE = 'leatherType'

// BTW, this query is ugly looking.
const query = graphql`
  query CategoryFilters {
    textile: allColorItem(filter: {
      discontinued: {ne: true}
      pattern: {category: {id: {eq: "textile"}}}
    }) {
      colors: distinct(field: colors___code)
      contents: distinct(field: pattern___contents___code)
      designs: distinct(field: pattern___designs___code)
    }
    trim: allColorItem(filter: {
      discontinued: {ne: true}
      pattern: {category: {id: {eq: "trim"}}}
    }) {
      colors: distinct(field: colors___code)
      contents: distinct(field: pattern___contents___code)
      designs: distinct(field: pattern___designs___code)
    }
    leather: allColorItem(filter: {
      discontinued: {ne: true}
      pattern: {category: {id: {eq: "leather"}}}
    }) {
      colors: distinct(field: colors___code)
    }
    leatherTypes: allPatternsJson(
      filter: {category: {id: {eq: "leather"}}}
      sort: {fields: name}
    ) {
      edges {
        node {
          name
          patternId
        }
      }
    }
  }
`
const selector = _.get('data.edges')
const leatherTypeField = {
  code: LEATHER_TYPE,
  id: LEATHER_TYPE,
  name: LEATHER_TYPE,
  label: 'Type',
  title: 'Type',
  position: 0,
}

function shouldDisplayGroup({ showCategory }, categoryId) {
  const showContents = !['leather'].includes(categoryId)
  const showDesigns = categoryId !== 'leather'
  return (item) => {
    if (item.name === 'cg') return showCategory
    if (item.name === 'ct') return showContents
    if (item.name === 'dn') return showDesigns
    return true
  }
}
function getFilterFields(catId, leatherPatternInfo, search) {
  if (catId !== LEATHER) return filterFields
  const optCodeLabel = {}
  const buckets = leatherPatternInfo.map(({ node: { patternId, name } }) => {
    optCodeLabel[patternId] = name
    return ({ key: patternId, selected: patternId === search.query })
  })
  const field = {
    ...leatherTypeField,
    buckets,
    optCodeLabel,
  }
  return [field, ...filterFields]
}
function getAggregation(catId, activeField, aggregations, catFilterInfo) {
  if (activeField.buckets) return activeField
  const aggregation = activeField.buckets ? activeField : aggregations[activeField.code]
  if (catId === 'any') return aggregation
  const validOptions = _.get([catId, activeField.id], catFilterInfo)
  if (_.isEmpty(validOptions)) return aggregation
  aggregation.buckets = aggregation.buckets.filter(
    ({ key, selected }) => (selected || validOptions.includes(key)),
  )
  return aggregation
}
function getFilterGroups(catId, activeField, aggregations, filterOpts, catFilterInfo) {
  const filterGroups = _.filter(
    shouldDisplayGroup({ showCategory: false }, catId),
    aggregations,
  )
  const aggregation = getAggregation(catId, activeField, aggregations, catFilterInfo)
  return {
    aggregation,
    filterGroups: catId === LEATHER ? [filterOpts[0], ...filterGroups] : filterGroups,
  }
}
function hasSearch(search) {
  const hasTextSearch = !!search.query
  const hasFilters = !_.isEmpty(search.filters)
  // const hasNonCatFilters = !_.isEmpty(_.unset('cg', search.filters))
  return hasTextSearch || hasFilters
}

function Filters({
  aggregations, activeFilters, metaInfo, onChange, showFiltersByDefault,
}) {
  const { leatherTypes, ...categoryFilterInfo } = useStaticQuery(query)
  const catId = metaInfo.categoryId
  const filterOpts = getFilterFields(catId, leatherTypes.edges, metaInfo.search)
  const [activeFieldCode, setActiveField] = useState(filterOpts[0].code)
  const [showFilters, setFilterVisibility] = useState(showFiltersByDefault)
  const activeField = _.find({ code: activeFieldCode }, filterOpts) || filterOpts[0]
  function handleChange(code, checked) {
    if (activeField.code === LEATHER_TYPE) {
      return onChange(['search', 'query'], checked ? code : null)
    }
    const filters = activeFilters[activeField.code] || []
    const path = ['search', 'filters', activeField.code]
    if (checked) return onChange(path, filters.concat(code))
    return onChange(path, _.without([code], filters))
  }

  const { aggregation, filterGroups } = getFilterGroups(
    catId, activeField, aggregations, filterOpts, categoryFilterInfo,
  )
  const searching = hasSearch(metaInfo.search)
  const ClearAllButton = (
    <button
      className={classnames('clearAll', { disabled: !searching })}
      style={{ pointerEvents: searching ? 'auto' : 'none' }}
      disabled={!searching}
      type="button"
      onClick={() => onChange(
        ['search', 'filters'],
        // { cg: metaInfo.search.filters.cg },
      )}
    >
      Clear All
    </button>
  )

  return (
    <div className="collection-filters">
      <button className="filters toggle" type="button" onClick={() => setFilterVisibility(!showFilters)}>
        Filter
        { showFilters ? <span className="dropdown close" /> : <span className="dropdown open" /> }
      </button>
      { showFilters && (
        <button className="close btn-small" type="button" onClick={() => setFilterVisibility(false)}>
          X
        </button>
      )}
      {
        showFilters && (
          <div className="filtersWrapper">
            <FilterGroups
              activeId={activeField.code}
              clearBtn={ClearAllButton}
              items={filterGroups}
              onChange={setActiveField}
              metaInfo={metaInfo}
              className="activeField"
            />
            <FilterOptions
              aggregation={aggregation}
              labels={activeField.optCodeLabel}
              onChange={handleChange}
              className="filterOptions"
              showCount={false}
              showInactive
            />
          </div>
        )
      }
    </div>
  )
}
Filters.propTypes = {
  aggregations: PropTypes.object,
  activeFilters: PropTypes.object,
  metaInfo: PropTypes.object.isRequired,
  showFiltersByDefault: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}
Filters.defaultProps = {
  showFiltersByDefault: false,
}
export default Filters
