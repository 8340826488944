import _ from 'lodash/fp.js'
import { propDo } from 'prairie'
import validateZip from './password.js'
import { ANY_CAT } from './path.js'
import { filterFields } from '../data/filter-fields.js'

export const isBrowser = () => typeof window !== 'undefined'
const getData = (input) => (_.has('data', input) ? getData(input.data) : input)
const catInfo = _.find({ id: 'category' }, filterFields)
const catFilterCode = catInfo.code
const cats = catInfo.options
export const catsVisible = cats.filter(({ hidden }) => !hidden)
const catsById = _.keyBy('id', cats)
const catsByCode = _.keyBy('code', cats)
const getCatById = _.propertyOf(catsById)
const catsBySlug = _.keyBy('slug', cats)
export const getCatBySlug = _.propertyOf(catsBySlug)
const getCategory = _.flow(getCatById, _.defaultTo(catsById[ANY_CAT]))

function getMetaCatId({ search: { filters } }) {
  const catFilter = filters[catFilterCode]
  if (catFilter && catFilter.length === 1) return catsByCode[catFilter[0]].id
  return catsById[ANY_CAT].id
}

const getFilterTtl = ({ label, hint }) => label || hint
const getItemId = (pattern, colorItem) => `${pattern.patternId}-${colorItem.colorId}`
export const buildItemId = (item) => getItemId(item.pattern, item)
const getPatternItemId = (pattern, index) => getItemId(pattern, pattern.colorItems[index])

export const getLocation = () => (isBrowser() && window.location) || {}
export const getHash = () => getLocation().hash || ''
export const getSearch = () => getLocation().search || ''

export function createStore(initState = {}) {
  let state = initState
  return {
    getItem: (path, defaultVal) => (path ? _.getOr(defaultVal, path, state) : state),
    setItem: (path, value) => { state = _.set(path, value, state) },
  }
}
const hasHistory = () => isBrowser() && typeof window.history.pushState == 'function'
export function addHistory(nextPath) {
  if (hasHistory()) window.history.pushState({ key: Date.now().toString() }, null, nextPath)
}
export function replaceHistory(nextPath) {
  if (hasHistory()) window.history.replaceState({ key: Date.now().toString() }, null, nextPath)
}
export function createHistoryListener(onChange) {
  if (!hasHistory()) return _.noop
  function popstateListener() {
    const location = { ...window.location, state: window.history.state }
    onChange({ location, action: 'POP' })
  }
  window.addEventListener('popstate', popstateListener)
  function cleanup() {
    // console.log('cleanup popstate listener')
    window.removeEventListener('popstate', popstateListener)
  }
  return cleanup
}
export const store = createStore()
export function getItem(key) {
  if (isBrowser()) return window.localStorage.getItem(key)
  return store.getItem(key)
}
export function setItem(key, val) {
  if (isBrowser()) return window.localStorage.setItem(key, val)
  return store.setItem(key, val)
}
export const fromVal = propDo('target.value')
export const imgUrl = (imgUrlBase, imgPath, imgixExt) => (imgPath ? `${imgUrlBase}/${imgPath}${imgixExt || ''}` : null)

// Should something show up based on options
// filterState(opts, column)
export const filterState = _.curry(
  (
    {
      displayStyle, isAnon, isPrint,
    },
    {
      hideIfAnon, hideIfAuth, hideOnDetail, hideOnPrint, hideOnList, hideOnGrid,
    },
  ) => {
    if ((isAnon !== false) && hideIfAnon) return false
    if (displayStyle === 'detail' && hideOnDetail) return false
    if (displayStyle === 'list' && hideOnList) return false
    if (displayStyle === 'grid' && hideOnGrid) return false
    if (isPrint && hideOnPrint) return false
    if (isAnon === false && hideIfAuth) return false
    return true
  },
)

export {
  getCategory,
  getData,
  getFilterTtl,
  getItemId,
  getMetaCatId,
  getPatternItemId,
  validateZip,
}
