module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-netlify-cms@5.15.0_gatsby@3.15.0_netlify-cms-app@2.15.72_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/rg-web/rg-web/src/cms/index.js","manualInit":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@3.15.0_gatsby@3.15.0_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"793b4782a5b41a3d894fe68162d7fefc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
