import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import Telephone from './telephone.jsx'

function EmailTelephones({ email, telephoneNumbers }) {
  return (
    <ul className="connect list-reset">
      {email && (<li><a href="mailto:">{email}</a></li>)}
      {telephoneNumbers && _.map(Telephone, telephoneNumbers)}
    </ul>
  )
}
EmailTelephones.propTypes = {
  email: PropTypes.string,
  telephoneNumbers: PropTypes.arrayOf(PropTypes.object),
}
EmailTelephones.defaultProps = {
  email: null,
  telephoneNumbers: null,
}
export default EmailTelephones
