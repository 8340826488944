import _ from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { getData } from '../../utils/index.js'

import Nav from '../common/nav.jsx'
import LogoThicker from '../common/logoThicker.jsx'

const query = graphql`
  query {
    data:markdownRemark(fields: {fileName: {eq: "primary-nav"}}) {
      data:frontmatter {
        data:nav { action, code, name, url, group, hideIfAnon, hideIfAuth }
      }
    }
  }
`

function Header({
  metaInfo, onChange, search, toggleSearch,
}) {
  const { path } = metaInfo
  const navItems = getData(useStaticQuery(query))
  return (
    <header>
      <div id="logo">
        <Link to="/">
          <LogoThicker />
        </Link>
      </div>
      {search}
      {_.isArray(navItems) && (
        <Nav
          activePath={path}
          items={navItems}
          metaInfo={metaInfo}
          onChange={onChange}
          toggleSearch={toggleSearch}
        />
      )}
    </header>
  )
}
Header.propTypes = {
  metaInfo: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  search: PropTypes.element,
  toggleSearch: PropTypes.func.isRequired,
}
Header.defaultProps = {
  onChange: null,
  search: null,
}
export default Header
