import _ from 'lodash/fp'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { createHandleChange } from '../../utils/collection.js'
import { collectionPathEncode, getSearchQuery, isDetailPg } from '../../utils/path.js'

import Header from './header.jsx'
import Footer from './footer.jsx'
// import Alerts from './alerts.jsx'
import Search from './search-bar.jsx'

function Layout({
  children, className, footer, metaInfo, path, onChange,
}) {
  const meta = metaInfo || { path }
  const shouldShowSearch = meta.isClientApp || meta.isSearching || false
  const [showSearch, setShowSearch] = useState(shouldShowSearch)
  // console.log('shouldShowSearch', shouldShowSearch, meta)
  if (isDetailPg(meta)) {
    return (
      <main style={{ maxWidth: '100%' }}>
        {children}
      </main>
    )
  }
  const handleChange = createHandleChange(metaInfo, onChange)

  const search = <Search onChange={handleChange} value={getSearchQuery(meta)} />
  const toggleSearch = () => {
    setShowSearch(!showSearch)
    if (!meta.isSearching && meta.categoryId !== 'any') {
      navigate(
        '/collection/[...]',
        { state: { nextPath: collectionPathEncode({ categorySlug: 'any' }) } },
      )
    }
  }
  return (
    <main className={className}>
      <Header metaInfo={meta} onChange={handleChange} toggleSearch={toggleSearch} />
      {showSearch && search}
      {children}
      <Footer text={footer} />
    </main>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
  footer: PropTypes.string,
  metaInfo: PropTypes.shape({
    path: PropTypes.string,
  }),
  onChange: PropTypes.func,
  path: PropTypes.string,
  showingSearch: PropTypes.bool,
}
Layout.defaultProps = {
  className: undefined,
  footer: null,
  metaInfo: null,
  onChange: null,
  path: null,
  showingSearch: false,
}
export default Layout
