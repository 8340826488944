import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/layout/index.jsx'
import NewItem from '../components/new-item.jsx'
import RelatedList from '../components/detail/related-list.jsx'

function Pattern({ pattern, imgUrl, metaInfo }) {
  const meta = { ...metaInfo, backTo: metaInfo.path }
  return (
    <div id="related-colors" className="relatedColors pattern card">
      <h2 className="uppercase">Colors</h2>
      <RelatedList pattern={pattern} imgUrlBase={imgUrl} metaInfo={meta} />
    </div>
  )
}

function NewRelease(props) {
  const { data, path } = props
  const { pattern } = data
  const metaInfo = {
    path,
  }

  return (
    <Page metaInfo={metaInfo} className="new-item-detail">
      <div className="card">
        <NewItem {...pattern} readMore={false} />
        <Pattern pattern={pattern} metaInfo={metaInfo} />
      </div>
    </Page>
  )
}
NewRelease.defaultProps = {
  data: {},
}
export default NewRelease

export const pageQuery = graphql`
  query NewItemNoticePage($slug: String!) {
    pattern:patternsJson(patternId: {eq: $slug}) {
      patternId
      category {
        id
        slug
      }
      colorItems {
        color
        colorId
        images { blurhash, keyword }
      }
      description
      name
      datePublished(formatString: "MMMM YYYY")
      image
      patternId
    }
  }
`
