import React from 'react'
// import PropTypes from 'prop-types'

function LoadingImg() {
  const loadingText = 'Loading image...'
  return (
    <div className="loading">
      {/* <i className="spinningGif" /> */}
      <img src="/images/loading.gif" alt={loadingText} />
      <span className="hidden">{loadingText}</span>
    </div>
  )
}
LoadingImg.propTypes = {
}
LoadingImg.defaultProps = {
}
export default LoadingImg
