import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Filmstrip from '../components/filmstrip'
// import { getData } from '../utils'

function PageTemplate({ path, data }) {
  const { frontmatter, html } = data.data
  return (
    <Layout path={path}>
      <div className="page">
        {/* <h1 className="uppercase center">{frontmatter.title}</h1> */}
        { frontmatter.imageGallery && <div className="filmstripWrapper"><Filmstrip items={frontmatter.imageGallery} /></div>}
        {html && (
        <div
          className="page-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        )}
      </div>
    </Layout>
  )
}
export default PageTemplate
export const pageQuery = graphql`
  query($collection: String!, $fileName: String!) {
    data:markdownRemark(fields: {
      fileName: {eq: $fileName}
      collection: {eq: $collection}
    }) {
      html
      frontmatter {
        urlSlug
        title
        imageGallery {
          image
          alt
        }
      }
    }
  }
`
