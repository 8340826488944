import _ from 'lodash/fp'
import React from 'react'

import OfficeContact from './contact.jsx'

/* eslint-disable react/jsx-props-no-spreading */
function OfficeLi(props) {
  const uniqueKey = _.get('fields.fileName', props)
  // {/* { isUserShowroom && <p style={styles.notice}>{ACTIVE_TEXT}</p>} */}
  return (
    <li key={uniqueKey} className="showroom">
      <OfficeContact {...props} />
    </li>
  )
}
function OfficeGroup({ fieldValue, nodes }) {
  const uniqueKey = fieldValue.split('_')[1]
  return (
    <ul key={uniqueKey} className={`${uniqueKey} stripe list-reset`}>
      {_.map(OfficeLi, nodes)}
    </ul>
  )
}
export default OfficeGroup
