import _ from 'lodash/fp'
import { setFieldWith } from 'prairie/lib/fields'
import React from 'react'
import { useUserShowroom } from '../../services/auth.js'
import OfficeGroup from './group.jsx'

import '../../styles/contact.css'

function getAdminAreas(showroomId, groups) {
  function checkMatch(ids) {
    if (!ids) return false
    const active = ids.includes(showroomId)
    // console.log(showroomId, ids, active)
    return active
  }
  return groups.map(_.update(
    'nodes',
    _.map(setFieldWith('isUserShowroom', 'frontmatter.showroomIds', checkMatch)),
  ))
}
function ContactSection({
  led, dek, samples, header, officeGroups,
}) {
  const showroomId = useUserShowroom()
  const adminAreas = showroomId ? getAdminAreas(showroomId, officeGroups) : officeGroups
  console.log('showroom', showroomId)
  return (
    <section className="showrooms">
      <div className="topMatter">
        <h2 className="uppercase">{header}</h2>
        <p className="blurb">{led}</p>
        <p className="blurb">{dek}</p>
        <p className="blurb">{samples}</p>
      </div>
      <div className="page-content">
        {_.map(OfficeGroup, adminAreas)}
      </div>
    </section>
  )
}
export default ContactSection
