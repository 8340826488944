import _ from 'lodash/fp.js'
import { onTrue } from 'understory'
import {
  replaceField, setField, setFieldWith, toObject,
} from 'prairie'
// import { readJsonSync } from 'fs-extra'
import fields from '../../static/fields.json'
import categories from '../../static/categories.json'

function fillOpt({ id }) {
  const cat = _.find({ id }, categories)
  return cat.columns
}
const fillOpts = _.map(setField('columns', fillOpt))
function createOptSearch({ code, label, alias }) {
  return ([code, (alias ? `${alias.join(' ')} ${label}` : label)])
}
const createSearch = _.flow(_.map(createOptSearch), _.fromPairs)

const createFilterFields = _.flow(
  _.filter(_.overEvery([
    _.matches({ filter: true }),
    (x) => x.id !== 'tags',
  ])),
  _.map(_.flow(
    replaceField('options', (opts) => opts.map((x, index) => _.set('index', index, x))),
    onTrue(({ id }) => id === 'category', _.update('options', fillOpts)),
    setFieldWith('optSearch', 'options', createSearch),
  )),
  _.sortBy('position'),
)
export const filterFields = createFilterFields(fields)
export const filterFieldsNoCat = _.tail(filterFields)
