import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import collectionInfo from '../../data/collection.mjs'
import pagerStyles from './pager-styles.js'
import Select from '../editable/select.jsx'
import ChangePage from './change-page.jsx'
import Filters from './filters.jsx'

import '../../styles/collection.css'
import '../../styles/collectionFilters.css'

const { defaultMeta, displayStyles, pageSizes } = collectionInfo

function Pager({
  itemsCount, metaInfo, onChange,
}) {
  const getDisplayInfo = (styleId) => displayStyles.find(
    ({ value }) => value === styleId,
  ) || {}
  const createChangeHandler = (key) => (e) => onChange(key, e.target.value)
  const {
    aggregations, currentPage, hasNextPage, hasPreviousPage,
    pageCount, perPage, displayStyle, search, sortSlug,
  } = metaInfo
  const displayInfo = getDisplayInfo(displayStyle)
  const pagePosition = `${currentPage} / ${pageCount}`
  function changeDisplayStyle(e) {
    const nextDisplayInfo = getDisplayInfo(e.target.value)
    const nextMeta = {
      displayStyle: e.target.value,
      currentPage: displayStyle === 'detail' ? 1 : currentPage,
    }
    if (nextDisplayInfo.perPage) nextMeta.perPage = nextDisplayInfo.perPage
    if (displayInfo.perPage) nextMeta.perPage = defaultMeta.perPage
    const nextDefaultSort = _.get('sortByOptions[0].value', nextDisplayInfo)
    if (nextDefaultSort && !_.some({ value: sortSlug }, nextDisplayInfo.sortByOptions)) {
      // console.log(sortSlug, 'not found switching to', nextDefaultSort)
      nextMeta.sortSlug = nextDefaultSort
    }
    return onChange(nextMeta)
  }
  const changePerPage = (e) => onChange({
    perPage: e.target.value,
    currentPage: 1,
  })
  const PrevPg = (
    <ChangePage
      nextPage={hasPreviousPage ? currentPage - 1 : null}
      onChange={onChange}
      prev
    />
  )
  const NextPg = (
    <ChangePage
      nextPage={hasNextPage ? currentPage + 1 : null}
      onChange={onChange}
    />
  )
  if (displayInfo.perPage && displayInfo.perPage < 2) {
    return (
      <div id="detail-pager" style={pagerStyles[displayStyle]}>
        {PrevPg}
        {NextPg}
      </div>
    )
  }
  return (
    <div id="pager" style={pagerStyles[displayStyle]}>
      {PrevPg}
      <div className="pagecount">{pagePosition}</div>
      {!displayInfo.perPage && (
        <Select
          label="View"
          className="small-stack"
          onChange={changePerPage}
          options={pageSizes}
          value={perPage && perPage.toString()}
        />
      )}
      <Filters
        activeFilters={search.filters}
        aggregations={aggregations}
        onChange={onChange}
        metaInfo={metaInfo}
        showFiltersByDefault={itemsCount === 0}
      />
      {displayInfo.sortByOptions.length > 1 && (
        <Select
          label="Sort by"
          className="sortBy"
          onChange={createChangeHandler('sortSlug')}
          options={displayInfo.sortByOptions}
          value={sortSlug}
        />
      )}
      <Select
        label="Display As"
        className="display-style"
        onChange={changeDisplayStyle}
        options={displayStyles.filter(_.negate(_.get('hidden')))}
        value={displayStyle}
      />
      {NextPg}
    </div>
  )
}

Pager.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  metaInfo: PropTypes.shape({
    aggregations: PropTypes.object.isRequired,
    displayStyle: PropTypes.string.isRequired,
    sortSlug: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
    pageCount: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    search: PropTypes.shape({
      filters: PropTypes.object,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Pager
