// How do I write these to account for:
// - No price vs. Price?
// - Textiles vs. Passementerie vs. Leathers all have slightly different columns?
// price and content have widths set here; the rest are in pricelist.css
// print.css needed to be able to override some of them

function cellStyles({ length }, fieldId) {
  const styles = {
    theadBase: {
    },
    'category.code': {
    },
    color: {
    },
    id: {
    },
    name: {
    },
    pattern: {
    },
    price: {
    },
    imgPath: {
    },
    content: {
      width: length > 7 ? '25%' : '30%',
    },
    repeat: {
    },
    approxWidth: {
    },
  }
  return styles[fieldId]
}
export default cellStyles
