import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Item from './item.jsx'

function ItemGrid({
  items, metaInfo, onChange,
}) {
  return (
    <div className={classnames(metaInfo.categoryId, 'items')}>
      <ul className="list-reset item-grid">
        {_.map(
          (item) => (
            <Item
              key={item.id}
              item={item}
              metaInfo={metaInfo}
              onChange={onChange}
            />
          ),
          items,
        )}
      </ul>
      {(!items || !items.length) && <p className="text-center p4">No items match your search or filter criteria at this time</p>}
    </div>
  )
}
ItemGrid.defaultProps = {
}
ItemGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  metaInfo: PropTypes.shape({
    categoryId: PropTypes.string,
    path: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ItemGrid
