import _ from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useHotkeys } from 'react-hotkeys-hook'
import Page from './index.jsx'
import { getData } from '../../utils/index.js'
import { createHandleChange } from '../../utils/collection.js'

import Pager from '../collection/pager.jsx'
import ItemGrid from '../item-grid/index.jsx'
import ColorGrid from '../item-grid/color.jsx'
import Detail from '../detail/index.jsx'
import Debug from '../detail/debug.jsx'
import Pricelist from '../pricelist/table.jsx'

const displayTemplates = {
  detail: Detail,
  detailx: Debug,
  grid: ItemGrid,
  'grid-color': ColorGrid,
  list: Pricelist,
}

const query = graphql`
  query CollectionMeta {
    data: markdownRemark(fields: {fileName: {eq: "collection"}}) {
      data: frontmatter {
        footer
        auxText
        leatherText
      }
    }
  }
`

/* eslint-disable react/jsx-no-bind */
function Collection({
  items,
  metaInfo,
  onChange,
}) {
  const { auxText, leatherText, footer } = getData(useStaticQuery(query))
  const handleChange = createHandleChange(metaInfo, onChange)
  const { categoryId, displayStyle } = metaInfo
  useHotkeys('ctrl+c', () => onChange((meta) => _.set(
    'displayStyle',
    meta.displayStyle === 'grid-color' ? 'grid' : 'grid-color', meta,
  )))
  const ItemViewer = displayTemplates[displayStyle]
  console.log('Collection', _.now())
  const footerHtml = categoryId === 'trim' ? `<p>${auxText}</p>${footer}` : categoryId === 'leather' ? `<p>${leatherText}</p>${footer}` : footer
  return (
    <Page className={displayStyle} footer={footerHtml} metaInfo={metaInfo} onChange={handleChange}>
      <Pager
        metaInfo={metaInfo}
        onChange={handleChange}
        itemsCount={items.length}
      />
      <ItemViewer
        item={items.length === 1 ? items[0] : {}}
        items={items}
        metaInfo={metaInfo}
        onChange={handleChange}
      />
    </Page>
  )
}

Collection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  metaInfo: PropTypes.shape({
    displayStyle: PropTypes.string.isRequired,
    perPage: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func,
}

export default Collection
