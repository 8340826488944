import React, { useState } from 'react'
import PropTypes from 'prop-types'

import RelatedList from './related-list.jsx'
import styles from './related-styles.js'

function RelatedItems({
  activeId, pattern, isOpenDefault, metaInfo, onChange,
}) {
  const [isOpen, setOpen] = useState(isOpenDefault)
  const toggle = isOpen ? () => setOpen(false) : () => setOpen(true)
  return (
    <div id="related-colors" className="relatedColors relative" style={isOpen ? styles.open : styles.closed}>
      <button type="button" className="colors-header" onClick={toggle}>
        Colors
      </button>
      { isOpen && <RelatedList activeId={activeId} pattern={pattern} metaInfo={metaInfo} onChange={onChange} /> }
    </div>
  )
}
RelatedItems.defaultProps = {
  isOpenDefault: false,
}
RelatedItems.propTypes = {
  activeId: PropTypes.string.isRequired,
  metaInfo: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  pattern: PropTypes.object.isRequired,
  isOpenDefault: PropTypes.bool,
}

export default RelatedItems
