import { map, isNumber, isString } from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

function SelectOption({ value, label, active }) {
  return (
    <option className={classnames({ active })} key={value} value={value}>
      {label}
    </option>
  )
}
SelectOption.defaultProps = {
  active: false,
}
SelectOption.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

// function opts(arg) {
//   if (isString(arg)) return { value: arg, label: arg }
//   if (isNumber(arg)) return { value: arg.toString(), label: arg.toString() }
//   return arg
// }

function opts(arg) {
  if (isString(arg)) return { value: arg, label: arg }
  if (isNumber(arg)) return { value: arg.toString(), label: arg.toString() }
  return arg
}

function Select({
  className, label, onChange, options, value,
}) {
  if (!options || !options.length) return <div>NO OPTIONS</div>

  return (
    <div className={classnames('inputSelect', className)}>
      <div className="customDropdown">
        {label && <span>{label}</span>}
        <select onChange={onChange} value={value}>
          {map((opt) => SelectOption(opts(opt)), options)}
        </select>
      </div>
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
}
Select.defaultProps = {
  className: undefined,
  label: null,
  value: '-',
}
export default Select
