import _ from 'lodash/fp.js'
import { getFields } from 'prairie'
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Logo from '../common/logo.jsx'
// import Address from '../office/address.jsx'
// import EmailTelephones from '../office/email-telephones.jsx'

// eslint-disable-next-line no-template-curly-in-string
const TOKEN = '${dateModified}'

const query = graphql`
  query PrintCoverPage {
    office: markdownRemark(fields: {fileName: {eq: "corporate-headquarters"}}) {
      address: frontmatter {
        streetAddress
        addressLocality
        addressRegion
        postalCode
        telephoneNumbers {
          type
          number
        }
        email
      }
    }
    site {
      meta: siteMetadata {
        email
        dateModified
      }
    }
    text: markdownRemark(fields: {fileName: {eq: "collection"}}) {
      text: frontmatter {
        text: dek
      }
    }
  }
`
const selector = _.flow(
  getFields({
    address: 'office.address',
    site: 'site.meta',
    text: 'text.text.text',
  }),
)
function PrintCoverPage({ category }) {
  const { address, site, text } = selector(useStaticQuery(query))
  const { dateModified, email } = site

  const dateString = new Date(dateModified).toLocaleDateString()
  return (
    <div className={classNames('coverpage')}>
      <div className="logo">
        <Logo />
      </div>
      <div>
        {/* <Address {...address} />
        <EmailTelephones
          email={address.email || email}
          telephoneNumbers={address.telephoneNumbers}
        /> */}
        <h3 className="uppercase">
          {/* print out correct collection name, for all print null, be blank */}
          {(category && category.label) || 'ALL ITEMS'}
        </h3>
        <p dangerouslySetInnerHTML={{ __html: text.replace(TOKEN, `<span class="date-now">${dateString}</span>`) }} />
      </div>
    </div>
  )
}
PrintCoverPage.propTypes = {
  category: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
}
export default PrintCoverPage
