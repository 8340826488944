import { useContext, useState, useRef } from 'react'
import { pull, remove } from 'lodash/fp'

import { GlobalContext } from './createContext.js'
import { useLocalStorage } from '../utils/hooks.js'

export const GlobalState = () => {
  const [favorites, setFavorites] = useLocalStorage('favorites', [])
  const [faveAlerts, setFaveAlerts] = useState([])

  // ref to use current value inside setTimeout
  const alertRef = useRef(faveAlerts)
  alertRef.current = faveAlerts

  const addToFaves = (item) => {
    setFavorites([...favorites, item.id])
    setFaveAlerts([...faveAlerts, item])
    setTimeout(() => {
      setFaveAlerts(remove({ id: item.id }, alertRef.current))
    }, 5000)
  }

  const removeFromFaves = (item) => {
    setFavorites(pull(item.id, favorites))
    setFaveAlerts(remove({ id: item.id }, faveAlerts))
  }

  const closeAlert = (item) => {
    setFaveAlerts(remove({ id: item.id }, faveAlerts))
  }

  return {
    favorites,
    setFavorites,
    addToFaves,
    removeFromFaves,
    closeAlert,
    faveAlerts,
  }
}

export const useStore = () => {
  const store = useContext(GlobalContext)
  return store
}

export default useStore
