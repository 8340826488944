import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getCollectionMeta } from '../utils/collection.js'
import Collection from '../components/layout/collection.jsx'
import { useUserAnon } from '../services/auth.js'

function CollectionList({ data, pageContext, path }) {
  const { items: { edges, pageInfo } } = data
  const metaInfo = getCollectionMeta(pageContext, pageInfo, path)
  const isAnon = useUserAnon()
  metaInfo.isAnon = isAnon
  return (
    <Collection
      metaInfo={metaInfo}
      items={edges}
    />
  )
}

CollectionList.propTypes = {
  pageContext: PropTypes.shape({
    categorySlug: PropTypes.string.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
  data: PropTypes.shape({
    category: PropTypes.shape({
      label: PropTypes.string.isRequired,
      columns: PropTypes.arrayOf(PropTypes.object),
    }),
    items: PropTypes.shape({
      pageInfo: PropTypes.object,
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
}
export default CollectionList

export const pageQuery = graphql`
  query CollectionListCategoryItems($id: String, $perPage: Int, $skip: Int) {
    items: allPatternsJson(
      filter: {
        category: { id: { eq: $id } }
        discontinued: { ne: true }
        unpublished: { ne: true }
      }
      sort: { fields: [name, id], order: ASC }
      limit: $perPage
      skip: $skip
    ) {
      edges {
        node {
          ...PatternFields
          category {
            code
            id
            slug
          }
          colorItems {
            ...ItemFields
          }
        }
      }
      pageInfo {
        ...PagerFields
      }
    }
  }
`
