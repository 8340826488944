import _ from 'lodash/fp.js'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { DEFAULT_SHOWROOM, getUser } from '../services/auth.js'
import { catsVisible } from '../utils/index.js'
import { collectionPathEncode } from '../utils/path.js'

import Page from '../components/layout/index.jsx'
import Contact from '../components/office/contact.jsx'
import Address from '../components/office/address.jsx'
import CollectionLink from '../components/common/collection-link.jsx'

import '../styles/account.css'

const fields = [
  { label: 'Account Name', id: 'name' },
  { label: 'Account Number', id: 'customerNumber' },
  { label: 'Email', id: 'email' },
  { label: 'Phone', id: 'phoneNumber' },
]

const pdfPathEncode = (slug, dateModified, uid) => `/pdf/${slug}.pdf?date=${dateModified}&uid=${uid}`

function AcctWrap({ children, label }) {
  return (
    <div className="account-item">
      <h3 className="uppercase">{label}</h3>
      {children}
    </div>
  )
}

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-target-blank */
function TradeAccount({ data: { data, officeData, site } }) {
  const { frontmatter, html } = data
  const { dek, title } = frontmatter
  const { offices } = officeData
  const { meta: { dateModified } } = site
  const [me, setActiveUser] = useState({ username: null })
  const metaInfo = site.meta

  useEffect(() => {
    const user = getUser()
    if (user.isAnon) {
      navigate('/login')
      return
    }
    setActiveUser(user)
  }, [me.username])
  const path = '/account'
  if (!me.username) {
    return (
      <Page path={path}>
        <h2>{title}</h2>
        {dek}
      </Page>
    )
  }
  const showroomId = _.getOr(DEFAULT_SHOWROOM, 'showroom.id', me)
  const showroom = offices.find(
    ({ frontmatter: { showroomIds } }) => showroomIds && showroomIds.includes(showroomId),
  )
  return (
    <Page path={path}>
      <div className="trade-acct text-center">
        <div className="sub-nav topMatter pricelists">
          <h3 className="uppercase">Price List</h3>
          <div className="listWrapper flex">
            <ul className="list-reset flex">
              <p>View Website Price List</p>
              {catsVisible.map(({ id, label, slug }) => (
                <li key={id} className="flex">
                  <span className="view">
                    <CollectionLink
                      name={`${label} Pricelist`}
                      metaInfo={metaInfo}
                      url={collectionPathEncode({ displayStyle: 'list', categorySlug: slug })}
                    >
                      {label}
                    </CollectionLink>
                  </span>
                </li>
              ))}
            </ul>
            <ul className="list-reset flex">
              <p>Click to download a PDF</p>
              {catsVisible.map(({ id, label, slug }) => (
                <li key={id} className="flex">
                  <span>
                    <a href={pdfPathEncode(slug, dateModified, me.customerNumber)} className="download">
                      {label}
                      {' '}
                      PDF
                    </a>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* <div
          className="text-area text-center"
          dangerouslySetInnerHTML={{ __html: html }}
        /> */}
        <div className="account-information">
          <h3 className="uppercase">Account Information</h3>
          {fields.map(({ label, id }) => (<AcctWrap key={id} label={label}>{me[id]}</AcctWrap>))}
          {me.address && (
            <AcctWrap label="Address">
              <Address
                addressCountry={null}
                addressLocality={me.city}
                addressRegion={me.state}
                postalCode={me.zip}
                streetAddress={me.address}
                streetAddressDetail={me.address2}
              />
            </AcctWrap>
          )}
          {showroom && (
            <AcctWrap label="Sales Representative">
              <Contact {...showroom} isUserShowroom />
            </AcctWrap>
          )}
        </div>
      </div>
    </Page>
  )
}
TradeAccount.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        dek: PropTypes.string.isRequired,
      }),
      html: PropTypes.string.isRequired,
    }),
    officeData: PropTypes.shape({
      offices: PropTypes.arrayOf(PropTypes.object),
    }),
    site: PropTypes.shape({
      meta: PropTypes.shape({
        dateModified: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default TradeAccount

export const pageQuery = graphql`
{
  data:markdownRemark(fields: {
    fileName: {eq: "account"}
  }) {
    id
    frontmatter {
      dek, title, intro
    }
    html
  }
  officeData: allMarkdownRemark(
    filter: {fields: {collection: {eq: "offices"}}}
    sort: {order: ASC, fields: [frontmatter___title]}
  ) {
    offices: nodes {
      ...OfficeFields
    }
  }
  site {
    meta: siteMetadata {
      dateModified
    }
  }
}
`
