import _ from 'lodash/fp'
import { setFieldWith } from 'prairie'
import createSearchIndex from 'itemsjs'
import {
  getCategory, getFilterTtl, getMetaCatId,
} from './index.js'
import collectionInfo from '../data/collection.mjs'

const { sortByOptions } = _.find({ value: 'grid' }, collectionInfo.displayStyles)

// name is hard coded in itemsjs
const searchableFields = ['id', 'color', 'content', 'designs']
function createAggregations(result, item) {
  const { code, options, multiple } = item
  return _.set(
    code,
    {
      size: options.length + 1,
      title: getFilterTtl(item),
      conjunction: !!multiple,
      // sort: multiple ? 'count' : 'term',
      sort: 'term',
    },
    result,
  )
}
const sortings = sortByOptions.reduce(
  (result, { value, sortPath }) => _.set(
    // Add sort value for the category sort order. Needed when viewing 'any'.
    value, { field: ['pattern.category.sortOrder', sortPath], order: ['asc', 'asc'] }, result,
  ), {},
)

// Build info sent to the search package.
export function getSearchConfig(fields) {
  const aggregations = fields.reduce(createAggregations, {})
  return ({
    aggregations,
    searchableFields,
    sortings,
  })
}
function getcolors({ colorPrimary, colorsSecondary }) {
  const others = colorsSecondary || []
  return [colorPrimary, ...others]
}
const logOnce = _.once((...args) => console.log(...args))

function getTags(options, pattern, colorItem) {
  function getTagVal({ collection, id }) {
    if (collection === 'color') return colorItem[id] ? id : null
    if (collection === 'pattern') return pattern[id] ? id : null
    return null
  }
  const tagVals = options.map(getTagVal)
  return tagVals
}
function getRawValue(collection, pattern, colorItem, id, options) {
  if (id === 'colors') return getcolors(colorItem)
  if (id === 'tags') return getTags(options, pattern, colorItem)
  return collection === 'color' ? colorItem[id] : pattern[id]
}
// Build values for Faceted fields.
const searchFields = (fields, pattern, colorItem) => _.fromPairs(fields.map(
  ({
    code, collection, id, multiple, optIdCode, options,
  }) => {
    const rawValue = getRawValue(collection, pattern, colorItem, id, options)
    if (!rawValue) return [code, rawValue]
    const value = multiple ? _.compact(rawValue)
      .map((opt) => optIdCode[opt.id] || optIdCode[opt]) : (rawValue.code || rawValue)
    return [code, value]
  },
))
// const joinLabels = _.flow(_.map('label'), _.join(' '))
// const getColorLabels = _.flow(getcolors, joinLabels)
function getTextSearch(fields, pattern, search) {
  const content = _.words(pattern.content).filter(isNaN)
  const createString = _.flow(
    _.flatMap(({
      code, multiple, optSearch,
    }) => (
      ((multiple ? search[code] : [search[code]]) || [])
        .map((x) => optSearch[x]))),
    _.concat(_.words(pattern.name)),
    _.concat(content),
    _.uniq,
    _.join(' '),
  )
  return createString(fields)
}
const getPatternWithCategory = setFieldWith('category', 'category.id', getCategory)
const getItems = (fields, patterns) => _.flatMap(
  (pattern) => pattern.colorItems.map((colorItem) => {
    const search = searchFields(fields, pattern, colorItem)
    const textSearch = getTextSearch(fields, pattern, search)
    return {
      ...colorItem,
      name: textSearch, // hack to work with itemjs lunr
      pattern: getPatternWithCategory(pattern),
      ...search,
    }
  }),
  patterns,
)

export function createSearch(patterns, filterFields) {
  const searchConfig = getSearchConfig(filterFields)
  const items = getItems(filterFields, patterns)
  const searchIndex = createSearchIndex(items, searchConfig)
  return searchIndex.search
}

export function getSearchOptions({
  currentPage, ids, perPage, search: { filters, query }, sortSlug,
}) {
  return {
    filters,
    ids,
    page: currentPage,
    per_page: perPage,
    query: (query ? _.trim(query) : query),
    sort: sortSlug,
  }
}

export function getPageInfo(pagination) {
  const { page, total } = pagination
  const pageCount = Math.ceil(total / pagination.per_page)
  return {
    pageCount,
    hasPreviousPage: page > 1,
    hasNextPage: page < pageCount,
    total,
  }
}
function hasSearch({ filters, query }) {
  return !!query || !_.isEmpty(filters)
}
// For dynamic pages.
export function getCollectionMeta(meta, searchResults) {
  const { data: { aggregations }, pagination } = searchResults
  return {
    categoryId: getMetaCatId(meta),
    ...meta,
    ...getPageInfo(pagination),
    aggregations,
    isClientApp: true,
    isSearching: hasSearch(meta.search),
  }
}
export function buildGetPosition(metaInfo) {
  const { currentPage, perPage } = metaInfo
  const offset = ((currentPage - 1) * perPage) + 1
  return (index) => index + offset
}
export function buildListItems(items, getPosition) {
  function reducer(result, item, currentIndex) {
    const position = getPosition(currentIndex)
    if (!result[item.pattern.patternId]) {
      // eslint-disable-next-line no-param-reassign
      result[item.pattern.patternId] = {
        ...item.pattern,
        colorItems: [],
        position,
      }
    }
    result[item.pattern.patternId].colorItems.push({
      ...item,
      position,
    })
    return result
  }
  const result = _.sortBy(['position'], items.reduce(reducer, {}))
  return _.map(
    (node) => ({ node }),
    result,
  )
}
export function buildResultItems(metaInfo, items) {
  const { displayStyle } = metaInfo
  const getPosition = buildGetPosition(metaInfo)
  if (displayStyle === 'list') return buildListItems(items, getPosition)
  return items
    .map((item, index) => _.set('position', getPosition(index), item))
    .filter((item) => !_.isEmpty(item.images))
}
