import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const height = '100%'
const width = '100%'

const color = _.flow(
  _.get('images'),
  _.find({ keyword: 'normal' }),
  _.get('colors[1].hex'),
)
function ItemGrid({
  items, metaInfo, onChange,
}) {
  const validItems = items.filter((x) => !_.isEmpty(x))
  return (
    <div className={classnames(metaInfo.categoryId, 'items')}>
      <ul className="list-reset item-grid">
        {_.map(
          (item) => (
            <li key={item.id}>
              <div
                className="itemWrapper"
                style={{
                  width, height, backgroundColor: color(item), objectFit: 'cover',
                }}
              >
                {item.id}
                <br />
                {item.colorPrimary && item.colorPrimary.id}
                <br />
                {item.colorPosition}
                <br />
                {color(item)}
              </div>
            </li>
          ),
          validItems,
        )}
      </ul>
      {(!items || !items.length) && <p className="text-center p4">No items match your search or filter criteria at this time</p>}
    </div>
  )
}
ItemGrid.defaultProps = {
}
ItemGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  metaInfo: PropTypes.shape({
    categoryId: PropTypes.string,
    path: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ItemGrid
