// WARNING: DO NOT EDIT THIS FILE! See /data/field-options.js
const info = {
  "categories": {
    "any": "",
    "textile": "cgtl",
    "passementerie": "cgpr",
    "leather": "cglr"
  }
}
export default info
