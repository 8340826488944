import _ from 'lodash/fp'

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getCollectionMeta } from '../utils/collection.js'
import Collection from '../components/layout/collection.jsx'
import { useUserAnon } from '../services/auth.js'

function CollectionGrid({ data, pageContext, path }) {
  const { items: { nodes, pageInfo } } = data
  console.log('GRID', _.now())
  const metaInfo = getCollectionMeta(pageContext, pageInfo, path)
  const isAnon = useUserAnon()
  metaInfo.isAnon = isAnon
  return (
    <Collection
      metaInfo={metaInfo}
      items={nodes}
    />
  )
}

CollectionGrid.propTypes = {
  pageContext: PropTypes.shape({
    categorySlug: PropTypes.string.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
  data: PropTypes.shape({
    items: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object),
      pageInfo: PropTypes.shape({
        perPage: PropTypes.number,
      }),
    }),
  }).isRequired,
}
export default CollectionGrid

export const pageQuery = graphql`
  query CollectionGridCategoryItems($id: String, $sortBy: ColorItemFieldsEnum!, $perPage: Int, $skip: Int) {
    items:allColorItem(
      filter: {
        images: {elemMatch: {path: {ne: null}}}
        pattern: {
          discontinued: {ne: true}
          category: {id: {eq: $id}}
        }
        discontinued: { ne: true}
        removed: {ne: true}
      }
      sort: { fields: [$sortBy, id], order: ASC }
      limit: $perPage
      skip: $skip
    ) {
      nodes {
        pattern {
          ...PatternFields
          category {
            id
            slug
            name
          }
          colorItems {
            color
            colorId
            images { keyword }
          }
        }
        ...ItemFields
      }
      pageInfo { ...PagerFields }
    }
  }
`
