import _ from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { createSearch } from '../../utils/search.js'
import { collectionPathParse } from '../../utils/path.js'
import { replaceHistory } from '../../utils/index.js'
import { filterFields } from '../../data/filter-fields.js'
import '../../utils/query.js'
import { useUserAnon } from '../../services/auth.js'
import CollectionSearch from '../../components/layout/collection-search.jsx'

// Collection Outer Component
// - Loads data for all items and creates search function.
const toBool = (x) => !!x
const rmColors = (func = _.negate) => _.filter(({ discontinued }) => func(discontinued))
function rmDiscontinuedIfAnon(patterns, isAnon, { search }) {
  const discActive = _.isArray(search.filters.tg) && search.filters.tg.includes('dd')
  const showDisc = isAnon && discActive
  if (!isAnon && !showDisc) return patterns
  const filterWith = showDisc ? toBool : _.negate(toBool)
  return patterns.map(_.update('colorItems', rmColors(filterWith)))
}
function Collection(props) {
  const { data: { flagged, patterns }, location: { pathname, state } } = props
  const isAnon = useUserAnon()
  if (_.isUndefined(isAnon)) return <div className="loading text">loading...</div>
  const pathInfo = collectionPathParse(pathname, isAnon)
  const patternItems = rmDiscontinuedIfAnon(patterns.nodes, isAnon, pathInfo)
  const search = createSearch(patternItems, filterFields)
  const nextPath = _.get('nextPath', state) || pathInfo.path
  console.log('Collection - Parent Render', pathname, nextPath, _.now())
  if (pathname !== nextPath) {
    console.log('replace history', pathname, nextPath)
    replaceHistory(nextPath)
  }
  return (
    <CollectionSearch
      key={nextPath}
      flagged={flagged.items}
      isAnon={isAnon}
      search={search}
      path={nextPath}
    />
  )
}
Collection.propTypes = {
  data: PropTypes.shape({
    flagged: PropTypes.shape({
      // Item Ids that have been flagged for internal purposes.
      items: PropTypes.arrayOf(PropTypes.string),
    }),
    patterns: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      nextPath: PropTypes.string,
    }),
  }).isRequired,
}
Collection.defaultProps = {
}
export default Collection
// filter: { discontinued: { ne: true } }
export const pageQuery = graphql`
  query CollectionSearchQuery {
    flagged: flaggedYaml { items }
    patterns: allPatternsJson(
      sort: { fields: [name, id], order: ASC }
    ) {
      nodes {
        ...PrintCollectionSearchFields
      }
    }
  }
`
