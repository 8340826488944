import { isEmpty } from 'lodash/fp'
import React from 'react'
import { graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik'
import { fieldValidation } from 'cape-validate'
import classNames from 'classnames'
import Page from '../components/layout/index.jsx'
import { validateZip } from '../utils/index.js'
import { createCollectionUrl } from '../utils/path.js'
import { handleLogin } from '../services/auth.js'

import '../styles/login.css'

const COLLECTION_URL = createCollectionUrl({
  displayStyle: 'list', categorySlug: 'textile',
})
const ACCOUNT_URL = '/account'

function LoginForm({ errors, isSubmitting }) {
  const hasErrors = !isEmpty(errors)
  return (
    <Form className="loginForm" role="form">
      <div className={classNames('form-group', { 'has-error': !!errors.username })}>
        <label className="sr-only accountNum" htmlFor="username">Account Number</label>
        <Field
          className="form-control"
          id="username"
          name="username"
          placeholder="Account Number"
          type="text"
          validate={fieldValidation(['numString', ['firstChar', '0'], ['length', 6]])}
        />
        <ErrorMessage name="username" component="div" />
      </div>
      <div className={classNames('form-group', { 'has-error': !!errors.password })}>
        <label className="sr-only zipCode" htmlFor="password">Zip Code</label>
        <Field
          className="form-control"
          id="password"
          name="password"
          placeholder="ZIP Code"
          type="password"
          validate={validateZip}
        />
        <ErrorMessage name="password" component="div" />
      </div>
      <button className="btn-outline" type="submit" disabled={isSubmitting || hasErrors}>
        Log In
      </button>
    </Form>
  )
}
function formValidate({ username, password }) {
  const errors = {}
  if (!username) errors.username = 'Required'
  if (!password) errors.password = 'Required'
  return errors
}
function handleSubmit(values, { setSubmitting, setFieldError }) {
  handleLogin(values).then((user) => {
    setSubmitting(false)
    if (user.isAuthenticated) {
      navigate(ACCOUNT_URL)
    } else {
      if (user.status === 404) setFieldError('username', user.message)
      if (user.isAnon) setFieldError('password', 'ZIP Code does not match.')
    }
  })
}
function Login({ data }) {
  const { frontmatter, html } = data.data
  const { intro, title } = frontmatter

  return (
    <Page path="/login">
      <div id="login">
        <div className="flexWrapper">
          <div className="loginCard">

            <p className="description">{intro}</p>

            <Formik
              initialValues={{ username: '', password: '' }}
              onSubmit={handleSubmit}
              validate={formValidate}
            >
              {LoginForm}
            </Formik>
            <div
              className="tradeInfo"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </div>
    </Page>
  )
}

Login.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        intro: PropTypes.string.isRequired,
      }),
      html: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default Login

export const pageQuery = graphql`
{
  data:markdownRemark(fields: {
    fileName: {eq: "login"}
  }) {
    id
    frontmatter {
      title, intro
    }
    html
  }
}
`
