import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Autoplay, Lazy, A11y,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'

import Slide from './slide.jsx'

function LandingSlider({ items }) {
  const initialSlide = _.random(0, items.length - 1)
  return (
    <Swiper
      modules={[Autoplay, Lazy, A11y]}
      // modules={[Lazy, A11y]}
      autoplay={{
        delay: 0,
        disableOnInteraction: true,
      }}
      speed={25000}
      initialSlide={initialSlide}
      loop
      lazy
      spaceBetween={0}
      slidesPerView="auto"
      hashNavigation={{ replaceState: true, watchState: true }}
    >
      {_.map(
        (item) => (
          <SwiperSlide key={_.last(item.image.split('/'))} data-hash={item.id}>
            <Slide item={item} />
          </SwiperSlide>
        ),
        items,
      )}
    </Swiper>
  )
}

LandingSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default LandingSlider
