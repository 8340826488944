import React from 'react'
import PropTypes from 'prop-types'

const changePageInfo = {
  prev: { className: 'prev', symbol: 'angle-left', char: '<' },
  next: { className: 'next', symbol: 'angle-right', char: '>' },
}

function ChangePage({
  nextPage, prev, onChange,
}) {
  const disabled = !nextPage
  const info = prev ? changePageInfo.prev : changePageInfo.next
  const className = `control ${info.className}${(disabled && ' disabled') || ''}`

  return (
    <button
      className={className}
      type="button"
      disabled={disabled}
      value={nextPage}
      onClick={(e) => onChange('currentPage', nextPage)}
    >
      <span className="hidden">
        {info.className}
      </span>
      <span className={`icon ${info.symbol}`}>
        {info.char}
      </span>
    </button>
  )
}
ChangePage.propTypes = {
  nextPage: PropTypes.number,
  prev: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}
ChangePage.defaultProps = {
  nextPage: null,
  prev: false,
}
export default ChangePage
