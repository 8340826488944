import _ from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Address from './address.jsx'
import EmailTelephones from './email-telephones.jsx'

const ACTIVE_TEXT = 'Your Sales Representative'
const styles = {
  active: {
    border: '1px solid #262019',
    padding: '1rem',
    backgroundColor: '#fcfbf8;',
    boxShadow: '-.25rem .75rem 1rem .2rem rgba(132, 132, 132, 0.25), .25rem .25rem 3rem .25rem rgba(38, 32, 25,.1), 0 .5rem  .75rem -.25rem rgba(132, 132, 132, 0.25)',
  },
  notice: {
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#262019',
    border: '1px solid #262019',
    textTransform: 'uppercase',
    padding: '0',
    fontSize: '.8rem',
    letterSpacing: '1px',
    fontWeight: '700',
    margin: '0 -1rem 0 -1rem',
    position: 'relative',
    top: '-1rem',
  },
}
const hasAddress = ({ streetAddress, addressRegion }) => addressRegion || streetAddress
function OfficeContact({
  fields, frontmatter, isUserShowroom,
}) {
  const uniqueKey = fields.fileName
  const {
    contact, email, telephoneNumbers, title,
  } = frontmatter
  // console.log(isUserShowroom, title)
  // const style = isUserShowroom ? styles.active : {}
  return (
    <div className={classNames({ active: isUserShowroom })}>
      <h3>{title}</h3>
      <p className="contactName">{contact}</p>
      {/* http://microformats.org/wiki/adr */}
      {hasAddress(frontmatter) && <Address {...frontmatter} />}
      <EmailTelephones email={email} telephoneNumbers={telephoneNumbers} />
    </div>
  )
}
OfficeContact.propTypes = {
}
OfficeContact.defaultProps = {
}
export default OfficeContact
