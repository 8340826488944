import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/layout/index.jsx'

// styles
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4,
}

const browser = typeof window !== 'undefined' && window

// markup
const NotFoundPage = () => (
  browser && (
    <Page path="404" className="fourOhFour">
      <h1 className="uppercase center gigantic">404</h1>
      <h2 className="uppercase center">Page not found</h2>
      <p style={paragraphStyles}>
        Sorry, we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in
            {' '}
            <code style={codeStyles}>src/pages/</code>
            .
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>
        {' '}
        or
        {' '}
        <Link to="/collection/any/grid/name/48/1">Try searching the collection</Link>
        .
      </p>
    </Page>
  )
)

export default NotFoundPage
