import { map } from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Autoplay, Navigation, Pagination, A11y,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'

const IMG_WIDTH = 1500

function Filmstrip({ items }) {
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination, A11y]}
      speed={1000}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      loop
      navigation
      pagination={{ clickable: true }}
      spaceBetween={10}
      slidesPerView="auto"
      centeredSlides
    >
      {map(
        (item) => (
          <SwiperSlide key={item.alt} style={{ flexShrink: 1 }}>
            <img src={`/${item.image}?w=${IMG_WIDTH}`} alt={item.alt} title={item.alt} />
          </SwiperSlide>
        ),
        items,
      )}
    </Swiper>
  )
}

Filmstrip.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Filmstrip
