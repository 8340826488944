import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classnames from 'classnames'
import {
  collectionPathEncode, itemUrl, getNormalImg, normalImgPath,
} from '../../utils/path.js'

import Info from '../common/item-info.jsx'
import RelatedList from '../detail/related-list.jsx'
import Image from '../common/image.jsx'

// import { useStore } from '../../store/globalState'

const CONTAINER_W = 1200

const imgSizes = {
  // default: '?w=500&h=374&crop=focalpoint&fit=crop&fp-x=.5&fp-y=.5&fp-z=2',
  default: '?w=390&h=330&crop=focalpoint&fit=crop&fp-x=.5&fp-y=.5&fp-z=2&q=60', // is this better size wise, at least for 3-up view?
  trim: '?w=1200&q=60',
}
function ItemLink({
  children, item, metaInfo, onChange,
}) {
  const { isSearching, path } = metaInfo
  if (isSearching) {
    // console.log('searching', item.position)
    const newMeta = { displayStyle: 'detail', perPage: 1, currentPage: item.position }
    const newPath = collectionPathEncode({ ...metaInfo, ...newMeta })
    // eslint-disable-next-line no-inner-declarations
    function handler(event) {
      event.preventDefault()
      onChange(newMeta)
    }
    return <a href={newPath} onClick={handler}>{children}</a>
  }
  return <Link to={itemUrl(item.id)} state={{ backTo: path }}>{children}</Link>
}

function GridItem({
  className, item, metaInfo, onChange,
}) {
  const [showColors, setShowColors] = useState(false)
  const [isHovering, setHover] = useState(false)
  if (!item.colorId || !item.pattern.category) {
    console.error('GridItem item error', item)
    return <li>Invalid Item</li>
  }
  const imagePath = normalImgPath(item)
  const { blurhash, pixelRatio } = getNormalImg(item.images) || {}
  const patternItems = item.pattern.colorItems
  const catId = item.pattern.category.id
  const imgix = imgSizes[catId] || imgSizes.default
  const blurHeight = catId === 'trim' ? Math.min(400, Math.round(pixelRatio * CONTAINER_W)) : '100%'
  // console.log(item.pattern.patternId, pixelRatio, style)
  // hash={blurhash}
  return (
    <li className={classnames(className, catId)}>
      <div className="itemWrapper" id={item.id} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

        <ItemLink item={item} metaInfo={metaInfo} onChange={onChange}>
          <Image
            path={imagePath}
            alt={item.id}
            title={item.id}
            imgix={imgix}
            width="100%"
            height={blurHeight}
          />
        </ItemLink>

        <div className="colorInfo stack">
          {patternItems.length > 1 && <button type="button" onClick={() => setShowColors(!showColors)}>Colors</button>}
          { showColors && (
            <div className="relatedColors">
              <RelatedList
                activeId={item.colorId}
                pattern={item.pattern}
                isOpenDefault
                metaInfo={metaInfo}
              />
            </div>
          )}
        </div>

        { !showColors && isHovering && (
          <div className="itemInfo">
            <Info item={item} metaInfo={metaInfo} showClose={false} />
          </div>
        )}
      </div>
    </li>
  )
}
GridItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    colorId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    pattern: PropTypes.shape({
      category: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      colorItems: PropTypes.arrayOf(PropTypes.object),
      patternId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  metaInfo: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,

  // onError: PropTypes.func,
}
export default GridItem
