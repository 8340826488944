import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import { getItemId } from '../../utils/index.js'
import { getNormalImg } from '../../utils/path.js'
import RelatedColor from './related-color.jsx'

/* eslint-disable no-plusplus */

function RelatedList({
  activeId, metaInfo, onChange, pattern,
}) {
  let index = 1
  return (
    <ul className="list-reset">
      {_.map(
        ({
          color, colorId, images,
        }) => (
          <li key={colorId}>
            <RelatedColor
              backTo={_.get('backTo', metaInfo)}
              isActive={activeId === colorId}
              color={color}
              colorId={colorId}
              categoryId={pattern.category.id}
              itemId={getItemId(pattern, { colorId })}
              image={getNormalImg(images)}
              onChange={onChange}
              patternId={pattern.patternId}
              index={index++}
            />
          </li>
        ),
        pattern.colorItems,
      )}
    </ul>
  )
}
RelatedList.propTypes = {
  activeId: PropTypes.string,
  metaInfo: PropTypes.shape({
    backTo: PropTypes.string,
  }),
  onChange: PropTypes.func,
  pattern: PropTypes.shape({
    category: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    colorItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    patternId: PropTypes.string.isRequired,
  }).isRequired,
}
RelatedList.defaultProps = {
  activeId: null,
  metaInfo: {},
}
export default RelatedList
