import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { getImgPath, itemUrl } from '../../utils/path.js'
import styles from './related-styles.js'
import Image from '../common/image.jsx'

function ActiveColor({ children, style }) {
  return (
    <span className="this-item" style={style || styles.active}>
      {children}
    </span>
  )
}
ActiveColor.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
}

function ColorLink({
  altTxt, backTo, children, index, itemId, onChange, patternId,
}) {
  if (onChange && index) {
    return (
      <button onClick={() => onChange({ search: { query: patternId }, currentPage: index })} type="button">
        {children}
      </button>
    )
  }
  return (
    <Link
      title={altTxt}
      to={itemUrl(itemId)}
      state={{ backTo }}
    >
      {children}
    </Link>
  )
}
ColorLink.propTypes = {
  altTxt: PropTypes.string.isRequired,
  backTo: PropTypes.string,
  children: PropTypes.node.isRequired,
  itemId: PropTypes.string.isRequired,
}
ColorLink.defaultProps = { backTo: null }

const imgSizes = {
  default: {
    imgix: '?w=128&h=128&crop=focalpoint&fit=crop&fp-x=.5&fp-y=.5&fp-z=5&fm=pjpg',
    width: '100%',
    height: '100%',
  },
  trim: {
    imgix: '?w=128&h=64&fit=crop&crop=entropy&fm=pjpg',
    width: '100%',
    height: '100%',
  },
}
function RelatedColor({
  backTo, categoryId, color, colorId, itemId, image, index, isActive, onChange, style, patternId,
}) {
  const { imgix, height, width } = imgSizes[categoryId] || imgSizes.default
  const altTxt = `${itemId} ${color}`
  const imagePath = image ? getImgPath(patternId, colorId, image.keyword) : null
  const hash = image ? image.blurhash : null
  const Element = isActive ? ActiveColor : ColorLink
  return (
    <Element
      altTxt={altTxt}
      backTo={backTo}
      itemId={itemId}
      index={index}
      onChange={onChange}
      patternId={patternId}
      style={style}
    >
      <Image
        alt={altTxt}
        height={height}
        width={width}
        path={imagePath}
        imgix={imgix}
        hash={hash}
      />
    </Element>
  )
}
RelatedColor.propTypes = {
  backTo: PropTypes.string,
  categoryId: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  colorId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  image: PropTypes.shape({
    blurhash: PropTypes.string.isRequired,
    keyword: PropTypes.string.isRequired,
  }),
  index: PropTypes.number,
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  style: PropTypes.bool,
  patternId: PropTypes.string.isRequired,
}
RelatedColor.defaultProps = {
  backTo: null,
  image: null,
  style: null,
  path: null,
}
export default RelatedColor
