import _ from 'lodash/fp'
import { navigate } from 'gatsby'
import {
  filterState, getCategory, getFilterTtl,
} from './index.js'
import { collectionPathEncode, getCategorySearch, getDisplayInfo } from './path.js'
import { filterFields } from '../data/filter-fields.js'

export function aggregationBuilder(searchFilters) {
  function createFieldAggregation(field) {
    const { code, options, position } = field
    const buckets = options.filter(({ hidden }) => !hidden)
    return {
      name: code,
      title: getFilterTtl(field),
      position,
      buckets: buckets.map((opt) => ({
        key: opt.code,
        selected: (_.isArray(searchFilters[code]) && searchFilters[code].includes(opt.code)),
      })),
    }
  }
  return _.flow(
    _.map(createFieldAggregation),
    _.keyBy('name'),
  )(filterFields)
}
// For static pre-render collection pages.
export function getCollectionMeta({ categorySlug, ...pageContext }, pageInfo, path) {
  const search = getCategorySearch(categorySlug)
  const aggregations = aggregationBuilder(search.filters)
  const metaInfo = {
    pathInput: path,
    search,
    ...pageContext,
    ...pageInfo,
    aggregations,
    isClientApp: false,
    isSearching: false,
  }
  return _.set('path', collectionPathEncode(metaInfo), metaInfo)
}

// Go to the dynamic search portion of the site.
const goToCollectionApp = _.flow(
  collectionPathEncode,
  (nextPath) => navigate('/collection', { state: { nextPath } }),
)
const debouncedGoToSearch = _.debounce(300, goToCollectionApp)

// Go to a static collection page that has been previously created.
const goToCollectionPg = _.flow(collectionPathEncode, navigate)

// Handle when a filter or page changes. Only on static (not already searching) pages.
export function handleUpdate(newMetaInfo, pathOrChangesObj) {
  // console.log(newMetaInfo)
  // If this is for a pre-rendered page navigate directly to it.
  if (_.isPlainObject(pathOrChangesObj)) return goToCollectionPg(newMetaInfo)
  if (pathOrChangesObj.includes('search')) return debouncedGoToSearch(newMetaInfo)
  // Assume we have a filter or text search query change. Go to collection app.
  // console.log('CollectionGrid', nextPath)
  return goToCollectionApp(newMetaInfo)
}
const categoryFilterPath = ['search', 'filters', 'cg']
// Prepare value for onChange handler.
export function createHandleChange(metaInfo, changeHandler) {
  const onChange = changeHandler || handleUpdate
  return (pathOrChangesObj, newValue) => {
    if (_.isPlainObject(pathOrChangesObj)) {
      return onChange({ ...metaInfo, ...pathOrChangesObj }, pathOrChangesObj, newValue)
    }
    if (!newValue) console.log('empty new value', pathOrChangesObj)
    let nextMeta = _.set(pathOrChangesObj, newValue, metaInfo)
    if (pathOrChangesObj.includes('search')) {
      const isNewTextSearch = pathOrChangesObj.includes('query') && !_.get(pathOrChangesObj, metaInfo)
      if (isNewTextSearch) nextMeta = _.unset(categoryFilterPath, nextMeta)
      nextMeta.currentPage = 1
    }
    return onChange(nextMeta, pathOrChangesObj, newValue)
  }
}

function customSort(opts, columns) {
  if (opts.displayStyle === 'list') {
    const { columnOrder } = getDisplayInfo('list')
    return _.sortBy(({ field }) => columnOrder.indexOf(field.id), columns)
  }
  return columns
}
export const fixColumns = (columns, opts, item) => customSort(opts, columns.filter(
  (column) => {
    const { field: { valuePath } } = column
    // Hide/remove column if no value found.
    if (_.isString(valuePath) && item && !_.get(valuePath, item)) return false
    return filterState(opts, column)
  },
))

// getColumns(metaInfo, opts, item) item?.pattern?.category?.id
function getColumnCat(item, categoryId = 'any') {
  const catId = item?.pattern?.category?.id || categoryId
  const cols = getCategory(catId).columns
  return cols
}
// getColumns(metaInfo, { isAnon })
export const getColumns = ({
  categoryId, displayStyle, pageType,
}, opts = {}, item) => fixColumns(
  getColumnCat(item, categoryId),
  { displayStyle, isPrint: pageType && pageType.includes('print'), ...opts },
  item,
)
