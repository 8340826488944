import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Image from '../common/image.jsx'

function getRulerPath({ categoryId, showXl }, showingMetric) {
  const size = showXl ? '2560' : '1536'
  const unit = showingMetric ? 'cm' : 'inch'
  return `ruler/${unit}/${categoryId}-${size}.png`
}

function Ruler({
  cmLabel, inchLabel, showMetricDefault, ...props
}) {
  const [showingMetric, setMetric] = useState(showMetricDefault)
  const cmClass = 'ruler-cm'
  const inClass = 'ruler-inches'
  const rulerClass = showingMetric ? cmClass : inClass
  return (
    <div className="ruler-wrap">
      <ul className="ruler-toggle list-reset">
        <li className={classnames(inClass, { active: !showingMetric })}>
          <button type="button" onClick={() => setMetric(false)}>{inchLabel}</button>
        </li>
        <li className={classnames(cmClass, { active: showingMetric })}>
          <button type="button" onClick={() => setMetric(true)}>{cmLabel}</button>
        </li>
      </ul>
      <div className="rulers">
        <Image
          alt={rulerClass}
          className={rulerClass}
          path={getRulerPath(props, showingMetric)}
        />
      </div>
    </div>
  )
}

Ruler.defaultProps = {
  cmLabel: 'Centimeters',
  inchLabel: 'Inches',
  showMetricDefault: false,
  showXl: false,
}
Ruler.propTypes = {
  categoryId: PropTypes.string.isRequired,
  cmLabel: PropTypes.string,
  inchLabel: PropTypes.string,
  showMetricDefault: PropTypes.bool,
  showXl: PropTypes.bool,
}

export default Ruler
