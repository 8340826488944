import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash/fp'
import cellStyles from './styles'

function HeaderRowCell({ label, length, fieldId }) {
  return (
    <td className={fieldId} style={cellStyles({ length }, fieldId)}>
      {label}
    </td>
  )
}

HeaderRowCell.propTypes = {
  label: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  fieldId: PropTypes.string.isRequired,
}

function PricelistHeader({
  columns, showFav,
}) {
  return (
    <thead>
      <tr>
        { showFav && <td className="favButton" />}
        {map(
          ({ label, field }) => (
            <HeaderRowCell
              key={field.id}
              label={label}
              fieldId={field.id}
              length={columns.length}
            />
          ),
          columns,
        )}
      </tr>
    </thead>
  )
}

PricelistHeader.defaultProps = {
  cellStyles: {},
  showFav: false,
}
PricelistHeader.propTypes = {
  cellStyles: PropTypes.object,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  showFav: PropTypes.bool,
}

export default PricelistHeader
