import React from 'react'
import PropTypes from 'prop-types'

import { GlobalContext } from './createContext.js'
import { GlobalState } from './globalState.js'

const StateProvider = ({ children }) => {
  const store = GlobalState()

  return (
    <GlobalContext.Provider value={store}>
      {children}
    </GlobalContext.Provider>
  )
}

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StateProvider
