import _ from 'lodash/fp.js'
import { oneOf } from 'understory'
import { createBasicValidator, fieldValidation } from 'cape-validate'

const COUNTRY_ZIP_CODES = [
  'Antigua',
  'AUSTRALIA',
  'Belgium',
  'Bermuda',
  'BRAIL',
  'BRAZIL',
  'CANADA',
  'CHILE',
  'CHINA',
  'ENGLAND',
  'FRANCE',
  'ITALY',
  'MEXICO',
  'NETHERLAND',
  'Norway',
  'Sweden',
  'UK',
]

// Zip code validation.
const validNumZip = fieldValidation(['numString', ['length', 5]])

const first10Chars = (x) => x.slice(0, 10)
const validZipCountries = _.map(_.flow(_.trim, first10Chars, _.toUpper), COUNTRY_ZIP_CODES)
const isValidCountry = _.flow(
  _.trim,
  first10Chars,
  _.toUpper,
  oneOf(validZipCountries),
)
const validZipCountry = fieldValidation([
  createBasicValidator(isValidCountry, 'No known account in that area'),
])
function validateZip(value) {
  const val = _.trim(value)
  if (/^[a-zA-Z]+$/.test(val)) {
    return validZipCountry(value)
  }
  return validNumZip(value)
}
export default validateZip
