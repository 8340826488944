import _ from 'lodash/fp'
import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/layout/index.jsx'
import ContactSection from '../components/office/index.jsx'

/* eslint-disable react/jsx-props-no-spreading */
export default function ContactPage({ data, path }) {
  const { pageData, officeData } = data // data.markdownRemark holds your post data

  return (
    <Page path={path}>
      <ContactSection
        {...pageData.frontmatter}
        officeGroups={officeData.group}
      />
    </Page>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    pageData:markdownRemark(fields: { fileName: { eq: $slug } }) {
      frontmatter {intro, header, title, led, dek}
    }
    officeData:allMarkdownRemark(
      filter: {fields: {collection: {eq: "offices"}}}
      sort: {order: ASC, fields: [fields___fileName]}
    ) {
      group(field: frontmatter___administrativeArea) {
        fieldValue
        nodes {
          ...OfficeFields
        }
      }
    }
  }
`
