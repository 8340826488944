import _ from 'lodash/fp'
import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/layout/index.jsx'
import NewRelease from '../components/new-item.jsx'

import '../styles/new-release.css'

export default function NewReleasePage(props) {
  const {
    data, path,
  } = props

  const { newReleaseData } = data
  return (
    <Page path={path}>
      <section className="releases">
        <div className="topMatter">
          <h2 className="uppercase">Recent Releases</h2>
        </div>
        <div className="page-content">
          {_.map(NewRelease, newReleaseData.nodes)}
        </div>
      </section>
    </Page>
  )
}

export const pageQuery = graphql`
  query AllReleases {
    newReleaseData:allPatternsJson(
      filter: {
        datePublished: {ne: null}
        description: {ne: null}
        image: {ne: null}
      }
      sort: {order: DESC, fields: [datePublished]}
    ) {
      nodes {
        description
        name
        datePublished(formatString: "MMMM YYYY")
        image
        patternId
      }
    }
  }
`
