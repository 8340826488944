import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { fromVal } from '../../utils/index.js'
import { SEARCH_Q_PATH } from '../../utils/path.js'

function noop(event) {
  event.preventDefault()
}
/* eslint-disable jsx-a11y/no-autofocus */
function SearchBar({ label, onChange, value }) {
  const [searchQuery, setQuery] = useState(value)
  // Needed faster updates of input and a way to trigger onChange.
  function searchChange(newVal) {
    setQuery(newVal)
    // If this is a new search we also want to reset the category.
    onChange(SEARCH_Q_PATH, newVal)
  }
  return (
    <form className="searchWrapper" onSubmit={noop}>
      <input
        autoFocus
        id="collection-search"
        type="text"
        aria-label="Search The Collection"
        placeholder={label}
        value={searchQuery}
        onChange={fromVal(searchChange)}
      />
      <label htmlFor="collection-search">{label}</label>
    </form>
  )
}
SearchBar.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
SearchBar.defaultProps = {
  label: 'Search The Collection',
  value: undefined,
}
export default SearchBar
