// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-jsx": () => import("./../../../src/pages/account.jsx" /* webpackChunkName: "component---src-pages-account-jsx" */),
  "component---src-pages-collection-jsx": () => import("./../../../src/pages/collection/[...].jsx" /* webpackChunkName: "component---src-pages-collection-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-releases-jsx": () => import("./../../../src/pages/releases.jsx" /* webpackChunkName: "component---src-pages-releases-jsx" */),
  "component---src-templates-collection-grid-jsx": () => import("./../../../src/templates/collection-grid.jsx" /* webpackChunkName: "component---src-templates-collection-grid-jsx" */),
  "component---src-templates-collection-list-jsx": () => import("./../../../src/templates/collection-list.jsx" /* webpackChunkName: "component---src-templates-collection-list-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-item-detail-jsx": () => import("./../../../src/templates/item-detail.jsx" /* webpackChunkName: "component---src-templates-item-detail-jsx" */),
  "component---src-templates-new-release-jsx": () => import("./../../../src/templates/new-release.jsx" /* webpackChunkName: "component---src-templates-new-release-jsx" */),
  "component---src-templates-new-releases-jsx": () => import("./../../../src/templates/new-releases.jsx" /* webpackChunkName: "component---src-templates-new-releases-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/page-template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-print-pdf-jsx": () => import("./../../../src/templates/print-pdf.jsx" /* webpackChunkName: "component---src-templates-print-pdf-jsx" */)
}

