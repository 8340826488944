import _ from 'lodash/fp.js'
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import '../utils/query.js'
import { collectionPathParse } from '../utils/path.js'
import { useUserAnon } from '../services/auth.js'

import Detail from '../components/detail/index.jsx'
// import Alerts from '../components/layout/alerts.jsx'

function ItemDetail({ data, location }) {
  const { item } = data
  const { pathname, state } = location
  const metaInfo = collectionPathParse(pathname)
  metaInfo.backTo = _.get('backTo', state)
  const isAnon = useUserAnon()
  metaInfo.isAnon = isAnon
  return (
    <main>
      <Detail
        item={item}
        metaInfo={metaInfo}
      />
    </main>
  )
}
ItemDetail.propTypes = {
  data: PropTypes.shape({
    item: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      backTo: PropTypes.string,
    }),
  }).isRequired,
}
export default ItemDetail

export const pageQuery = graphql`
  query CollectionItem($id: String!) {
    item: colorItem(id: { eq: $id }) {
      ...ItemFields
      pattern {
        category {
          code
          name
          columns(detailOnly: true) {
            field {
              id
            }
            hideIfAnon
            label
          }
          label
          id
          slug
        }
        ...PatternFields
        colorItems {
          color
          colorId
          images { blurhash, keyword, pixelRatio }
        }
      }
    }
  }
`
