import _ from 'lodash/fp.js'
import { flowP } from 'understory'
import fetch from 'cross-fetch'
import { useState, useEffect } from 'react'
import {
  isBrowser, getItem, getHash, getSearch, setItem,
} from '../utils/index.js'

const AUTH_CODE = 'with-authentication-please'
const PRINTING = 'print'
const DEFAULT_USR = { isAnon: true }
export const DEFAULT_SHOWROOM = '540'

export function getUser() {
  if (!isBrowser()) return { ...DEFAULT_USR, isBrowser: false }
  if (!getItem('app:user')) return DEFAULT_USR
  return JSON.parse(getItem('app:user'))
}

const setUser = (user) => setItem('app:user', JSON.stringify(user))
const getUserUrl = (uid) => `https://data.rogersandgoffigon.com/api/order-track/user/rg/${uid}`
const getUrl = ({ username, password }) => `${getUserUrl(username)}?zip=${password}`

function processLoginResponse(user) {
  const { error, customerNumber, matchesSearch } = user
  const isAuthenticated = (!error && customerNumber && matchesSearch)
  const userInfo = {
    ...user,
    username: customerNumber,
    isAuthenticated,
    isAnon: !isAuthenticated,
  }
  setUser(userInfo)
  return userInfo
}
export const getJson = flowP(
  getUrl,
  fetch,
  (res) => res.json(),
)
export const handleLogin = flowP(
  getJson,
  processLoginResponse,
)

export const isLoggedIn = () => {
  const user = getUser()
  return user.isAuthenticated
}
const hasAuthBypassHash = () => getHash().substr(1) === AUTH_CODE
const hasAuthBypassSearch = () => getSearch().substr(1) === PRINTING
const hasAuthBypass = (isAnonUser) => (
  isAnonUser === false || hasAuthBypassHash(isAnonUser) || hasAuthBypassSearch()
)
export const isAnon = (isAnonUser) => (hasAuthBypass(isAnonUser) ? false : getUser().isAnon)
export const logout = (callback) => () => {
  setUser(DEFAULT_USR)
  if (_.isFunction(callback)) callback()
}
export function useUserAnon(isAnonUser) {
  const [confirmedAnonUser, setAnonUser] = useState(isAnonUser)
  useEffect(() => {
    const result = isAnon(isAnonUser)
    if (result !== confirmedAnonUser) setAnonUser(result)
  }, [confirmedAnonUser, isAnonUser])
  return confirmedAnonUser
}
export function useUserShowroom() {
  const [showroomId, setShowroomId] = useState(null)
  useEffect(() => {
    const user = getUser()
    if (user.isAnon) return
    const id = _.getOr(DEFAULT_SHOWROOM, 'showroom.id', user)
    setShowroomId(id)
  })
  return showroomId
}
