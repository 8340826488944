import _ from 'lodash/fp'
import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/layout/index.jsx'
import NewRelease from '../components/new-item.jsx'

export default function NewReleasePage(props) {
  const {
    data, path,
  } = props

  const { pageData, newReleaseData } = data
  const { intro, title } = pageData.frontmatter
  // console.log(newReleaseData)
  return (
    <Page path={path} className={title}>
      <section className="newReleases">
        <div className="topMatter">
          <h2 className="uppercase">{intro}</h2>
        </div>
        <div className="page-content">
          {_.map(NewRelease, newReleaseData.items)}
        </div>
      </section>
    </Page>
  )
}

export const pageQuery = graphql`
  query NewReleases($slug: String!) {
    pageData:markdownRemark(fields: { fileName: { eq: $slug } }) {
      frontmatter {intro, title}
    }
    newReleaseData:allPatternsJson(
      filter: {
        image: {ne: null},
        description: {ne: null}
      }
      limit: 12
      sort: {fields: datePublished, order: DESC}
    ) {
      items:nodes {
        datePublished(formatString: "MMMM YYYY")
        description
        patternId
        image
        name
      }
    }
  }
`
