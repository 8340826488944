import _ from 'lodash/fp.js'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useHotkeys } from 'react-hotkeys-hook'

import Page from '../components/layout/index.jsx'
import LandingSlider from '../components/landing-slider/index.jsx'
import NoticeBox from '../components/notice-box.jsx'
import { useLocalStorage } from '../utils/hooks.js'

import '../styles/landing.css'

const getNoticeId = _.get('nodes[0].patternId')
const hasNotice = _.get('nodes.length')

function getBannerClose(banner, isLoaded, [closedNoticeId, closeNotice]) {
  if (!hasNotice(banner) || !isLoaded) return null
  const nextNoticeId = getNoticeId(banner)
  if (nextNoticeId === closedNoticeId) return null
  return () => closeNotice(nextNoticeId)
}

const IndexPage = ({ data: { banner, slides } }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const boxState = useLocalStorage('notice-box', null)
  const closeNotice = getBannerClose(banner, isLoaded, boxState)
  useHotkeys('esc', () => (_.isFunction(closeNotice) ? closeNotice() : null))
  useEffect(() => {
    if (!isLoaded) setIsLoaded(true)
  }, [isLoaded])
  return (
    <Page path="/" className="homeHack">
      {closeNotice && <NoticeBox closeNotice={closeNotice} item={banner.nodes[0]} />}
      <div id="landing">
        <LandingSlider items={slides.nodes} />
      </div>
    </Page>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    banner: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
    slides: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
  }).isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query {
    slides:allSlidesJson {
      nodes {
        image
        items {
          color
          colorId
          id
          name
          patternId
          removed
        }
      }
    }
    banner:allPatternsJson(
      filter: {
        newRelease: {eq: true},
        datePublished: {ne: null},
      }
      limit: 1
      sort: {fields: datePublished, order: DESC}
    ) {
      nodes {
        datePublished
        name
        patternId
        image
        description
      }
    }
  }
`
