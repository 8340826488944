import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'
import CollectionLink from './collection-link.jsx'

/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
function NavItem(props) {
  const {
    active, action, actions, group, url, name, onChange,
  } = props
  const Label = <span>{name}</span>
  const className = classnames({ active }, group)
  const isFilter = onChange && group.startsWith('collection')
  if (active && !isFilter) return (<li className={className}>{Label}</li>)
  if (url?.startsWith('http')) {
    return (
      <li className={className}><a alt={name} href={url}>{Label}</a></li>
    )
  }
  function handleClick(e) {
    if (action && actions[action]) {
      e.preventDefault()
      actions[action]()
    }
  }

  return (
    <li className={className}>
      {!isFilter && <Link alt={name} title={name} to={url} onClick={handleClick}>{Label}</Link>}
      {isFilter && <CollectionLink {...props}>{Label}</CollectionLink>}
    </li>
  )
}
NavItem.propTypes = {
  active: PropTypes.bool.isRequired,
  action: PropTypes.string,
  actions: PropTypes.shape({
    logout: PropTypes.func,
    search: PropTypes.func,
  }).isRequired,
  group: PropTypes.string,
  metaInfo: PropTypes.shape({
    isClientApp: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
NavItem.defaultProps = {
  action: null,
  group: 'nav-item',
  onChange: null,
}
export default NavItem
