import _ from 'lodash/fp'
import React from 'react'
import PropTypes from 'prop-types'

const propFields = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
)
// Compare for 0 because undefined is valid.
const isValid = (opt) => opt.selected || opt.doc_count !== 0
function isOptValid(showInactive) {
  return (opt) => {
    if (opt.key === 'null') return false
    return showInactive || isValid(opt)
  }
}
function FilterOptions({
  aggregation, labels, onChange, className, showInactive, showCount,
}) {
  const options = _.sortBy(
    ({ key }) => labels[key],
    aggregation.buckets.filter(isOptValid(showInactive)),
  )

  if (_.isEmpty(options)) return <div>{`No ${aggregation.title} options based on other selected filters.`}</div>
  return (
    <div role="group" aria-labelledby="checkbox-group" className={className}>
      {options.map((opt) => (
        <label key={opt.key}>
          <input
            disabled={!isValid(opt)}
            type="checkbox"
            name={aggregation.name}
            value={opt.key}
            onChange={(e) => onChange(e.target.value, !opt.selected)}
            checked={opt.selected}
          />
          <span>
            {labels[opt.key]}
            {showCount && opt.doc_count > 1 && <sup>{`[${opt.doc_count}]`}</sup>}
          </span>
        </label>
      ))}
    </div>
  )
}
FilterOptions.propTypes = {
  aggregation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    buckets: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  labels: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showCount: PropTypes.bool,
  showInactive: PropTypes.bool,
}
FilterOptions.defaultProps = {
  showCount: true,
  showInactive: false,
}
export default FilterOptions
