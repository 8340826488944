import React from 'react'

function LogoThicker() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 314 14">
      <g className="fillMeUp">
        <path d="M11.33,11.92a.6.6,0,0,0-.3.1v0h0l0,0a.77.77,0,0,1-.43.11c-.53,0-.91-.53-1.39-1.21L6.72,7.41c1-.2,3.8-.8,3.8-3.47S7.1,1.05,6.05,1.05c-.57,0-1.23,0-1.76.06H4.23c-.45,0-.9,0-1.24,0s-1.07,0-1.58-.06H1.25l-.79,0a.39.39,0,0,0-.36.36v0c0,.1,0,.28.17.33l.64.12.1,0L1.7,2c.09,1.19.09,3.43.09,5.07s0,3.94-.09,5.14a11.57,11.57,0,0,0-1.46.2A.25.25,0,0,0,0,12.7a.37.37,0,0,0,.35.35l.38,0H.85C1.28,13,2.07,13,3,13c.75,0,1.53,0,2.33.09h.21a.37.37,0,0,0,.35-.35.23.23,0,0,0-.21-.24h0a9.4,9.4,0,0,0-1.49-.22c-.1-1.29-.1-3.7-.1-5.13V2a3.08,3.08,0,0,1,1.13-.16A3.16,3.16,0,0,1,8.45,5c0,1.38-1,2.08-2.92,2.08A6.37,6.37,0,0,1,4.85,7a3.33,3.33,0,0,0-.38,0c-.1,0-.35,0-.35.25a.6.6,0,0,0,.17.34h0a1.67,1.67,0,0,1,.17.26l2.85,3.91.06.07c.63.84,1.17,1.57,2.17,1.57s2.15-.68,2.15-1.09A.39.39,0,0,0,11.33,11.92Z" />
        <path d="M23.54,1h-.22a6.74,6.74,0,0,0-4.53,1.66,5.88,5.88,0,0,0-1.95,4.5,5.91,5.91,0,0,0,1.67,4.32,6.66,6.66,0,0,0,4.81,1.73,6.16,6.16,0,0,0,4.42-1.81,6,6,0,0,0,1.74-4.24,6.31,6.31,0,0,0-1.77-4.6A5.83,5.83,0,0,0,23.54,1ZM27,7.11c0,2.62-1.18,5.45-3.77,5.45-2.93,0-4-2.94-4-5.45,0-3.47,1.45-5.46,4-5.46a3.22,3.22,0,0,1,3,1.93A8.21,8.21,0,0,1,27,7.11Z" />
        <path d="M47.93,6.86l-.44,0h0c-.33,0-.82.07-1.56.07s-1.46,0-1.89-.07H44l-.49,0c-.09,0-.35,0-.35.25,0,.09,0,.34.21.34h0A11.65,11.65,0,0,0,45,7.67v3.68l-.2.11a3.85,3.85,0,0,1-2.3.67,4.61,4.61,0,0,1-3.62-1.89,6.7,6.7,0,0,1-1.41-4.08c0-3.83,2.62-4.4,4.19-4.4a5,5,0,0,1,4,1.92c0,.7.14,1.88.56,1.88h.06l0,0a.56.56,0,0,0,.15-.31,4.86,4.86,0,0,0-.05-.58v0a8.1,8.1,0,0,1-.06-.87c0-.34,0-.69.06-1V2.69a5,5,0,0,0,0-.63l.06-.39,0-.2c.07-.48.13-.85.13-1s0-.35-.36-.35c-.18,0-.23.14-.28.33L46,.64v0a4.23,4.23,0,0,1-.16,1.07,7.74,7.74,0,0,0-3.29-.69,6.58,6.58,0,0,0-6.9,6.69,5.32,5.32,0,0,0,1.73,4.06,5.79,5.79,0,0,0,3.91,1.46,6.91,6.91,0,0,0,3.56-1,4,4,0,0,1,1.93-.67H47a.36.36,0,0,0,.35-.35.27.27,0,0,0-.09-.2l0,0V11l0,0c-.06-.06-.06-.33-.06-.85V7.63l.34-.07h0a2.63,2.63,0,0,0,.47-.1.37.37,0,0,0,.33-.35V7.05l0,0A.53.53,0,0,0,47.93,6.86Z" />
        <path d="M63.8.7h.3L63.86.46a.21.21,0,0,0-.23-.07.11.11,0,0,0-.07.06.25.25,0,0,0-.15.16,1.24,1.24,0,0,0-.2.56h0a45,45,0,0,1-4.69.2c-.7,0-1.3,0-1.82-.06h0c-.38,0-.74,0-1.06,0h0a10,10,0,0,0-1.39-.11c-.16,0-.31.13-.45.4v.06a.23.23,0,0,0,.22.24h0a9.38,9.38,0,0,0,1.49.21c.09,1.19.09,3.41.09,5s0,3.94-.1,5.14a12.09,12.09,0,0,0-1.46.2.25.25,0,0,0-.24.25.37.37,0,0,0,.36.35l.32,0h0a9.43,9.43,0,0,1,1.06-.08,25.08,25.08,0,0,1,2.94-.1A30,30,0,0,1,63.2,13a.36.36,0,0,0,0,.1.14.14,0,0,1,0,.06v0h0s0,0,0,.08a.6.6,0,0,0,.06.22h0a.36.36,0,0,1,.07.1.28.28,0,0,0,.25.16.37.37,0,0,0,.35-.35c0-.15,0-.37,0-.61v0c0-.3-.06-.66-.06-1a9,9,0,0,1,.07-.92v0c0-.17,0-.31,0-.4a.37.37,0,0,0-.35-.35.25.25,0,0,0-.25.21,2.14,2.14,0,0,0-.11.59c0,.17,0,.34-.08.5a23.48,23.48,0,0,1-4.87.71h0a.85.85,0,0,1-.42,0l-.12,0c-.1-1.18-.1-3.34-.1-4.79h.71a19.39,19.39,0,0,1,4.19.39,5.6,5.6,0,0,0,.22,1.32l0,0a.33.33,0,0,0,.31.15c.35,0,.35-.25.35-.35s0-.27,0-.49v0c0-.34-.08-.8-.08-1.39a10,10,0,0,1,.08-1.25,3,3,0,0,0,0-.33.38.38,0,0,0-.35-.36.39.39,0,0,0-.29.08.2.2,0,0,0-.06.14l-.06.23v0a3.55,3.55,0,0,0-.14.74,15.25,15.25,0,0,1-4.19.58h-.81c0-1.53,0-3.6.09-4.78h.61a12.57,12.57,0,0,1,4.64.69,2.78,2.78,0,0,1,.08.49h0a3.45,3.45,0,0,0,.11.62l0,0a.36.36,0,0,1,.07.1.28.28,0,0,0,.24.16c.17,0,.36-.15.36-.46,0-.15,0-.39,0-.62s-.06-.67-.06-1,0-.73.07-1S63.79.8,63.8.7Z" />
        <path d="M81.24,12a.7.7,0,0,0-.3.09.76.76,0,0,1-.43.12c-.54,0-.92-.54-1.4-1.21L76.73,7.62c1-.2,3.81-.8,3.81-3.47s-3.42-2.88-4.47-2.88c-.58,0-1.25,0-1.78.06h-.11c-.42,0-.85,0-1.17,0s-1.09,0-1.57-.06H71.2l-.73,0a.37.37,0,0,0-.35.35.23.23,0,0,0,.21.24h0a9.71,9.71,0,0,0,1.49.21c.1,1.19.1,3.41.1,5s0,3.94-.1,5.14a11.82,11.82,0,0,0-1.46.2.25.25,0,0,0-.24.25.37.37,0,0,0,.35.35l.36,0h.1C71.34,13,72.09,13,73,13c.74,0,1.53,0,2.33.09h.2a.38.38,0,0,0,.36-.35.23.23,0,0,0-.22-.24h0a10.15,10.15,0,0,0-1.48-.22c-.1-1.28-.1-3.6-.1-5.13s0-3.9.1-5.08a3.23,3.23,0,0,1,1.13-.16A3.16,3.16,0,0,1,78.57,5c0,1.38-1,2.08-2.92,2.08A5.55,5.55,0,0,1,75,7h0a2.84,2.84,0,0,0-.38,0c-.09,0-.35,0-.35.25a1,1,0,0,0,.34.61l2.64,4,.06.07c.63.84,1.17,1.57,2.17,1.57s2.15-.68,2.15-1.09A.37.37,0,0,0,81.24,12Z" />
        <path d="M93.73,6.13h0a9.5,9.5,0,0,0-2.06-.35h0c-1.64-.17-3.32-.35-3.32-1.84,0-1.67,2.34-1.76,2.81-1.76a5.17,5.17,0,0,1,3.45,1.3c0,1.18.18,1.34.35,1.34s.34-.17.35-.32h.11V2.57c0-.44.11-1.35.16-1.78v0c0-.27,0-.36,0-.4A.37.37,0,0,0,95.27,0c-.31,0-.43.45-.54,1.33a9.25,9.25,0,0,0-3-.49c-2.58,0-4.25,1.43-4.25,3.63a3.41,3.41,0,0,0,1.43,3,7.23,7.23,0,0,0,2.56.67c1.67.24,3.4.48,3.4,2.06,0,1-.88,1.54-2.61,1.54a5.59,5.59,0,0,1-4-1.72c0-.13,0-.29,0-.42h0c-.07-.58-.14-1.12-.5-1.12h-.06l0,0a.49.49,0,0,0-.15.31c0,.14,0,.44,0,.74v0c0,.53.07,1.26.07,1.78s0,1.12-.07,1.58V13c0,.34,0,.55,0,.67a.36.36,0,0,0,.35.35c.25,0,.3-.36.37-1v0c0-.15,0-.29,0-.41l.24.08h0a8,8,0,0,0,2.92.52c2.61,0,4.36-1.54,4.36-3.84A3,3,0,0,0,93.73,6.13Z" />
        <path d="M117.24,3.38a.89.89,0,1,0,0,1.76,1.25,1.25,0,0,0,.4-.06,5.73,5.73,0,0,1-1.28.92l-.89.57-.52.35-.1.07c-1.21.82-2.08,1.4-2.08,3.28,0,0,0,1.09.46,1.09.19,0,.23-.18.28-.43v0c0-.08,0-.17.06-.29s.09-.68.9-1.37c.25-.2.52-.4.71-.53l0,0c.2-.15.39-.29.52-.41a2.61,2.61,0,0,1,.62,1.69c0,1.63-1.64,2.61-3.24,2.61a3.13,3.13,0,0,1-3.23-3.24,2.41,2.41,0,0,1,2.39-2.71,2.58,2.58,0,0,1,.84.15,1,1,0,0,0,.32.06.35.35,0,0,0,.35-.35c0-.62-1.48-1.25-2.06-1.39a2,2,0,0,1-.36-1.25,1.64,1.64,0,0,1,1.75-1.54,1.85,1.85,0,0,1,1.33.61c.15.14.24.23.36.23a.37.37,0,0,0,.36-.35A2.57,2.57,0,0,0,112.6,1a2.45,2.45,0,0,0-2.68,2.36,2.17,2.17,0,0,0,.76,1.83,3.44,3.44,0,0,0-2.87,3.65,4.49,4.49,0,0,0,4.79,4.37,5,5,0,0,0,3.58-1.33A2.77,2.77,0,0,0,117,9.74a3.09,3.09,0,0,0-.68-1.87c.73-.54,2-1.55,2-3.09C118.33,3.9,117.93,3.38,117.24,3.38Z" />
        <path d="M141.93,6.86l-.42,0h0c-.32,0-.81.07-1.54.07s-1.47,0-1.9-.07h0l-.5,0c-.09,0-.35,0-.35.25,0,.09,0,.34.21.34h0a11.65,11.65,0,0,0,1.69.22v3.79l-.19.11a3.9,3.9,0,0,1-2.31.67,4.59,4.59,0,0,1-3.62-1.9,6.7,6.7,0,0,1-1.41-4.08c0-3.82,2.62-4.39,4.19-4.39a5,5,0,0,1,4,1.91c0,.71.14,1.88.56,1.88h.06l0,0a.49.49,0,0,0,.15-.31c0-.15,0-.35-.05-.57v0a8.11,8.11,0,0,1-.06-.88c0-.34,0-.68.06-1v0a3.78,3.78,0,0,0,0-.63c0-.2.06-.38.09-.56v0c.06-.44.13-.86.13-1s0-.35-.36-.35c-.2,0-.23.09-.34.43v0a4.37,4.37,0,0,1-.16,1.07,7.74,7.74,0,0,0-3.29-.69,6.58,6.58,0,0,0-6.9,6.69,5.32,5.32,0,0,0,1.73,4.06,5.79,5.79,0,0,0,3.91,1.46,6.84,6.84,0,0,0,3.55-1,4.07,4.07,0,0,1,1.94-.67H141a.36.36,0,0,0,.35-.35.27.27,0,0,0-.09-.2l0,0V11l0,0c-.06-.06-.06-.33-.06-.85V7.63l.34-.07h0a2.46,2.46,0,0,0,.47-.1.37.37,0,0,0,.33-.35V7.05l0,0A.49.49,0,0,0,141.93,6.86Z" />
        <path d="M154.8,1h-.22a6.74,6.74,0,0,0-4.53,1.66,5.88,5.88,0,0,0-2,4.5,5.91,5.91,0,0,0,1.67,4.32,6.68,6.68,0,0,0,4.81,1.73A6.15,6.15,0,0,0,159,11.35a6,6,0,0,0,1.74-4.24A6.36,6.36,0,0,0,159,2.49,5.87,5.87,0,0,0,154.8,1Zm-.22,11.61c-3.14,0-4-3.43-4-5.45,0-3.47,1.45-5.46,4-5.46a3.22,3.22,0,0,1,3,1.93,8.37,8.37,0,0,1,.75,3.53C158.35,9.62,157.36,12.56,154.58,12.56Z" />
        <path d="M176.76,1.21c0-.19,0-.35,0-.44a.37.37,0,0,0-.36-.35c-.09,0-.3,0-.34.19a1.24,1.24,0,0,0-.21.56h0a45,45,0,0,1-4.69.2c-.7,0-1.31,0-1.84-.06h-.06c-.38,0-.72,0-1,0h0a10.08,10.08,0,0,0-1.39-.11.37.37,0,0,0-.36.35.24.24,0,0,0,.22.25h0a10.76,10.76,0,0,0,1.48.21c.1,1.19.1,3.41.1,5s0,3.94-.1,5.14a11.76,11.76,0,0,0-1.45.2.25.25,0,0,0-.25.25.38.38,0,0,0,.36.36l.35,0h.05c.38,0,1.17-.08,2.13-.08,1.13,0,2,.06,2.33.09h.2a.38.38,0,0,0,.36-.36.23.23,0,0,0-.22-.24h0a9.42,9.42,0,0,0-1.48-.21c-.1-1.18-.1-3.34-.1-4.79h.7a19.26,19.26,0,0,1,4.19.39A4.26,4.26,0,0,0,175.59,9V9c.13.12.16.15.32.15a.31.31,0,0,0,.35-.36,3.74,3.74,0,0,0,0-.49v0c0-.32-.07-.8-.07-1.39a10.08,10.08,0,0,1,.08-1.23v0a2.11,2.11,0,0,0,0-.31.37.37,0,0,0-.35-.35.38.38,0,0,0-.29.08.16.16,0,0,0-.06.14l-.06.22v0a4.52,4.52,0,0,0-.15.73,15.15,15.15,0,0,1-4.19.58h-.7a32.72,32.72,0,0,1,.19-4.57h.62a12.55,12.55,0,0,1,4.64.7,2.39,2.39,0,0,1,.08.47v0a3.75,3.75,0,0,0,.11.6l0,0,.07.09a.28.28,0,0,0,.24.16c.17,0,.36-.14.36-.46,0-.08,0-.23,0-.4V3.34c0-.3-.07-.72-.07-1.09s0-.69.07-1Z" />
        <path d="M192.76,1.21c0-.21,0-.35,0-.44a.37.37,0,0,0-.35-.35c-.09,0-.31,0-.34.19a1.24,1.24,0,0,0-.21.56h0a45,45,0,0,1-4.69.2c-.71,0-1.31,0-1.84-.06h0c-.38,0-.74,0-1.06,0h0a10.1,10.1,0,0,0-1.4-.11.37.37,0,0,0-.35.35c0,.1.08.25.21.25h0a11.12,11.12,0,0,0,1.49.21c.09,1.19.09,3.41.09,5s0,3.94-.09,5.14a11.57,11.57,0,0,0-1.46.2.25.25,0,0,0-.24.25.38.38,0,0,0,.35.36l.36,0h.05c.38,0,1.17-.08,2.13-.08,1.13,0,2,.06,2.33.09h.2a.38.38,0,0,0,.35-.36.23.23,0,0,0-.21-.24h0a9.71,9.71,0,0,0-1.49-.21c-.08-1.06-.09-2.9-.09-4.58h.81a19.33,19.33,0,0,1,4.19.39,6.08,6.08,0,0,0,.22,1.23l0,0c.12.12.16.15.31.15a.31.31,0,0,0,.35-.35c0-.13,0-.29,0-.5v0a12.8,12.8,0,0,1-.07-1.37,10.07,10.07,0,0,1,.07-1.22V5.78c0-.11,0-.19,0-.26a.37.37,0,0,0-.35-.35.38.38,0,0,0-.29.08.2.2,0,0,0-.06.14l-.06.24v0a3.46,3.46,0,0,0-.14.73,15.25,15.25,0,0,1-4.19.58h-.71c0-1.53,0-3.61.1-4.78h.61a12.59,12.59,0,0,1,4.64.7,3.69,3.69,0,0,1,.07.47h0a2.94,2.94,0,0,0,.11.64l0,0,.06.08h0a.25.25,0,0,0,.24.16c.17,0,.35-.14.35-.46,0-.08,0-.24,0-.42v0c0-.29-.07-.74-.07-1.14s0-.71.07-1Zm-6.44,6Z" />
        <path d="M203.18,1.83c.23,0,.76-.07,1-.07a.25.25,0,0,0,.25-.25.37.37,0,0,0-.35-.35l-.36,0c-.42,0-1.19.09-2.18.09-1.16,0-2-.07-2.34-.1H199a.36.36,0,0,0-.35.35c0,.1.07.25.21.25h0a10.69,10.69,0,0,0,1.49.21c.09,1.19.09,3.41.09,5s0,3.94-.09,5.14a11.33,11.33,0,0,0-1.46.2.25.25,0,0,0-.24.25A.38.38,0,0,0,199,13l.26,0h.14c.38,0,1.18-.08,2.13-.08,1.14,0,2,.06,2.34.09h.2a.38.38,0,0,0,.35-.36.23.23,0,0,0-.22-.24,5,5,0,0,0-.59-.09h0a3.57,3.57,0,0,1-.85-.16c-.1-1.28-.1-3.55-.1-5.21,0-1.49,0-3.74.1-5Z" />
        <path d="M222.8,6.86l-.45,0h0c-.32,0-.81.07-1.55.07s-1.47,0-1.89-.07h0l-.5,0c-.09,0-.35,0-.35.25,0,.09,0,.34.21.34h0a11.47,11.47,0,0,0,1.69.22v3.79l-.2.11a3.85,3.85,0,0,1-2.3.67,4.59,4.59,0,0,1-3.62-1.9,6.7,6.7,0,0,1-1.41-4.08c0-3.82,2.62-4.39,4.19-4.39a5,5,0,0,1,4,1.91c0,.58.12,1.88.56,1.88h0l.05,0a.46.46,0,0,0,.15-.31c0-.13,0-.29-.05-.57v0a7.93,7.93,0,0,1-.06-.87,9.12,9.12,0,0,1,.06-1v0a4.44,4.44,0,0,0,0-.67,2.07,2.07,0,0,0,0-.22l0-.23,0-.13c.07-.48.13-.86.13-1s0-.35-.36-.35-.23.09-.35.43v0a4.23,4.23,0,0,1-.16,1.07,7.74,7.74,0,0,0-3.29-.69,6.58,6.58,0,0,0-6.9,6.69,5.32,5.32,0,0,0,1.73,4.06,5.76,5.76,0,0,0,3.9,1.46,6.75,6.75,0,0,0,3.56-1h0a4.09,4.09,0,0,1,1.93-.66h.21a.37.37,0,0,0,.35-.35.28.28,0,0,0-.1-.21h0v0l0-.05c-.06-.06-.06-.33-.06-.85V7.63l.37-.07a3.78,3.78,0,0,0,.46-.1.37.37,0,0,0,.33-.35V7.05l0,0A.48.48,0,0,0,222.8,6.86Z" />
        <path d="M235.65,1h-.2a6.74,6.74,0,0,0-4.53,1.66A5.85,5.85,0,0,0,229,7.11a5.91,5.91,0,0,0,1.67,4.32,6.66,6.66,0,0,0,4.81,1.73,6.12,6.12,0,0,0,4.41-1.81,6,6,0,0,0,1.75-4.24,6.32,6.32,0,0,0-1.75-4.62A5.86,5.86,0,0,0,235.65,1Zm3.56,6.16c0,2.62-1.18,5.45-3.76,5.45-2.94,0-4-2.94-4-5.45,0-3.47,1.45-5.46,4-5.46a3.21,3.21,0,0,1,3,1.93A8.21,8.21,0,0,1,239.21,7.11Z" />
        <path d="M259.93,1.16l-.2,0a17.39,17.39,0,0,1-1.81.09c-1,0-1.78-.07-2.12-.1h-.2a.37.37,0,0,0-.36.35.24.24,0,0,0,.22.25h0a12.76,12.76,0,0,0,1.6.21,33.33,33.33,0,0,1,.4,5.24v.14h.21V9.47l-7.14-8.26-.05-.05h-.06a4.42,4.42,0,0,0-.56.05h-.05a5.06,5.06,0,0,1-.66.06,8.62,8.62,0,0,1-1-.07h0c-.21,0-.36,0-.49,0a.37.37,0,0,0-.36.35c0,.19.15.23.34.29l.2.06a3.08,3.08,0,0,1,1.44,1.05h0l.23.23a57,57,0,0,1-.4,9.1,8,8,0,0,0-1.27.2.24.24,0,0,0-.23.25.37.37,0,0,0,.37.35h0c.1,0,1-.1,2-.1s1.76.06,2.1.09h.22a.37.37,0,0,0,.36-.35.23.23,0,0,0-.22-.24h0a11.31,11.31,0,0,0-1.7-.22A67.73,67.73,0,0,1,250.35,4l4.93,5.83c1.36,1.57,2,2.39,2.31,2.74l0,0c.41.52.45.56.63.56s.24-.26.24-.36v-.19c-.07-1.77-.1-3.59-.1-5.4A26.43,26.43,0,0,1,258.77,2a7.27,7.27,0,0,0,1.28-.21.24.24,0,0,0,.23-.25A.37.37,0,0,0,259.93,1.16Z" />
        <path d="M280.81,13.14V13c0-.34-.07-.73-.07-1s0-.63.06-1v-.06c0-.31,0-.6,0-.74a.37.37,0,0,0-.35-.36.26.26,0,0,0-.25.21,3.2,3.2,0,0,0-.11.69v.06c0,.2,0,.41-.07.57a10.59,10.59,0,0,1-4.31.9h-.61C275,11,275,8.73,275,7.11s0-3.84.1-5a10.73,10.73,0,0,0,1.45-.2.25.25,0,0,0,.25-.25.37.37,0,0,0-.35-.35,3,3,0,0,0-.37,0h0c-.42,0-1.19.08-2.14.08s-1.73-.05-2.15-.08h0a2.74,2.74,0,0,0-.35,0,.36.36,0,0,0-.36.35.23.23,0,0,0,.22.24h0a9.38,9.38,0,0,0,1.49.21c.09,1.19.09,3.41.09,5s0,3.94-.1,5.14a12.09,12.09,0,0,0-1.46.2.25.25,0,0,0-.24.25.37.37,0,0,0,.36.35l.31,0h0a9.43,9.43,0,0,1,1.06-.08,25.08,25.08,0,0,1,2.94-.1,24,24,0,0,1,4.4.19.29.29,0,0,0,0,.09.25.25,0,0,1,0,.07h0v.09a1.41,1.41,0,0,0,.06.22l0,0a.24.24,0,0,1,.06.08h0a.27.27,0,0,0,.24.16c.14,0,.29-.09.29-.22a.16.16,0,0,0,0-.12C280.83,13.38,280.82,13.28,280.81,13.14Z" />
        <path d="M296.56,1.27V1.2c0-.29,0-.54,0-.64a.37.37,0,0,0-.36-.35.35.35,0,0,0-.34.32,1.24,1.24,0,0,0-.11.56,28,28,0,0,1-4.72.28,28,28,0,0,1-4.72-.28,1.38,1.38,0,0,0-.12-.6l0,0-.06-.07h0l0,0a.28.28,0,0,0-.24-.16.37.37,0,0,0-.36.35c0,.08,0,.26,0,.61l0,.16c0,.33.05.71.05.92,0,.39-.06,1-.08,1.27v.15c0,.08,0,.14,0,.16a.37.37,0,0,0,.36.36.24.24,0,0,0,.24-.22,5.29,5.29,0,0,0,.21-1.32A7.65,7.65,0,0,1,290,2c.09,1.29.09,3.51.09,5.14s0,3.83-.09,5a11,11,0,0,0-1.45.2.25.25,0,0,0-.25.25.38.38,0,0,0,.35.36l.34,0h.09c.41,0,1.18-.08,2.11-.08s1.69.05,2.11.08h.06l.36,0a.36.36,0,0,0,.35-.36.22.22,0,0,0-.21-.24h0a9.71,9.71,0,0,0-1.49-.21c-.09-1.19-.09-3.41-.09-5s0-3.85.09-5.14a7.65,7.65,0,0,1,3.64.68,5,5,0,0,0,.18,1.25h0v.29h.09a.27.27,0,0,0,.26-.12.37.37,0,0,0,.1-.24s0-.07,0-.14l0-.26c0-.29-.08-.83-.08-1.18,0-.15,0-.52.06-.88Z" />
        <path d="M312.28,2.69a6.16,6.16,0,0,0-4.23-1.53c-.46,0-1.08,0-1.58.06h0c-.5,0-1,.05-1.37.05-.64,0-1.26,0-1.76-.07h0l-.75,0a.37.37,0,0,0-.35.35c0,.1.07.25.21.25h0a10.9,10.9,0,0,0,1.49.21c.09,1.19.09,3.41.09,5s0,3.94-.09,5.14a11.92,11.92,0,0,0-1.46.2.24.24,0,0,0-.24.25.38.38,0,0,0,.35.36l.36,0h0c.38,0,1.18-.08,2.13-.08.48,0,.88,0,1.28.06h0c.39,0,.73,0,1.12,0,3.23,0,6.48-1.88,6.48-6.06A5.56,5.56,0,0,0,312.28,2.69Zm-.68,4.52c0,3.17-1.56,5.13-4.08,5.13a6.06,6.06,0,0,1-1.2-.15l-.14,0c-.1-1.2-.1-3.61-.1-5s0-3.77.1-5.06a7.65,7.65,0,0,1,1.45-.18C310.77,1.87,311.6,5.36,311.6,7.21Z" />
      </g>
    </svg>
  )
}

export default LogoThicker
