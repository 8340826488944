import React, { useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

// import { getData } from '../../utils/index.js'

function Footer({ text }) {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site { currentYear: buildTime(formatString: "YYYY") }
      data:markdownRemark(fields: {
        fileName: {eq: "footer"}
      }) {
        id
        frontmatter {
          title
        }
        html
      }
    }
  `)
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window._ctct_m = '47ac5eaab70757785190e266825c5a8a'
    const script = document.createElement('script')
    script.setAttribute('id', 'signupScript')
    script.type = 'text/javascript'
    script.src = 'https://static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])
  const { data: { frontmatter, html }, site: { currentYear } } = data
  return (
    <footer>
      {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
      <p className="uppercase">
        {frontmatter.title}
        {' '}
        ©
        {' '}
        {currentYear}
      </p>

      {html && (
        <div
          className="page-content noTracking"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}

      <div className="ctct-inline-form" data-form-id="6d33385b-9704-43dd-b021-7eb26ab44fff" />

      <p className="reCaptcha privacy">
        Signup form protected by reCAPTCHA
        {' '}
        <br />
        <a href="https://policies.google.com/privacy?hl=en">Privacy</a>
        {' '}
        •
        {' '}
        <a href="https://policies.google.com/terms?hl=en)">Terms</a>
      </p>

    </footer>
  )
}

Footer.propTypes = {
  text: PropTypes.string,
  // children: PropTypes.node.isRequired,
}
Footer.defaultProps = { text: null }
export default Footer
